import React from 'react';
import PageableContainer from "../common/table/pageable-container";
import {removePromo, searchPromo} from "../../service/promo-service";

const sortOptions = [
    {label: 'Kezdet növekvő', field: 'start', order: 'asc'},
    {label: 'Kezdet csökkenő', field: 'start', order: 'desc'},
    {label: 'Vége növekvő', field: 'end', order: 'asc'},
    {label: 'Vége csökkenő', field: 'end', order: 'desc'},
];

function PromoContainer(props) {
    const getPromos = promos => {
        return promos.map(promo => ({
            Kód: promo.code,
            Kedvezmény: promo.percentage,
            Aktív: promo.active,
            Publikus: !!promo.restricted,
            Felsználva: promo.timeUsed,
        }));
    };

    return <PageableContainer sortOptions={sortOptions} fetchMethod={searchPromo} urlPrefix="kupon"
                              pageableDataMethod={getPromos} deleteMethod={removePromo} urlParam={'id'}/>
}

export default PromoContainer;