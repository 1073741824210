import * as _ from 'lodash'
import {alphabetical, percentage} from "./regex";

export const winery = {
    id: {value: '', required: true, regex: ''},
    name: {value: '', required: true, regex: _.cloneDeep(alphabetical)},
    wines: {value: [], required: true, regex: ''},
    categories: {value: [], required: true, regex: ''},
    year: {value: '', required: true, regex: ''},
    vineyardTerritory: {value: '', required: true, regex: _.cloneDeep(percentage)},
    vineyard: {value: '', required: true, regex: _.cloneDeep(percentage)},
    enologist: {value: '', required: true, regex: ''},
    displayNameIsWinery: {value: true, required: true, regex: ''},
    soilComposition: {value: '', required: true, regex: ''},
    grapeTypes: {value: '', required: true, regex: ''},
    logoImage: {value: '', required: true, regex: _.cloneDeep(alphabetical)},
    coverImage: {value: '', required: true, regex: _.cloneDeep(alphabetical)},
    wideImage: {value: '', required: true, regex: _.cloneDeep(alphabetical)},
    slideImages: {value: [], required: true, regex: _.cloneDeep(alphabetical)},
    description: {value: '', required: true, regex: ''},
    email: {value: "", required: false, regex: ''},
    phoneNumber: {value: "", required: false, regex: ''},
    webPage: {value: "", required: false, regex: ''},
    firstParagraphImages: {value: [], required: false, regex: ''},
    firstParagraph: {value: "", required: false, regex: ''},
    secondParagraphImages: {value: [], required: false, regex: ''},
    secondParagraph: {value: "", required: false, regex: ''},
    enologistOfEnologists: {value: false, required: false, regex: ''},
    enologistOfTheYear: {value: false, required: false, regex: ''},
    yearEnologistOfEnologists: {value: "", required: false, regex: ''},
    yearEnologistOfTheYear: {value: "", required: false, regex: ''},
    created: {value: null, required: false, regex: ''},
    active: {value: false, required: false, regex: ''},
};