import {get, post, put, remove} from "./auth-service";
import {resourceServer} from "../constants";

export const getAllWineries = () => {
    return get(`${resourceServer}/wineries`)
        .then(res => res.data)
};

export const searchWineries = (searchMap) => {
    return post(`${resourceServer}/wineries:search?all=true`, JSON.stringify(searchMap))
};

export const createWinery = winery => {
    return post(`${resourceServer}/wineries`, JSON.stringify(winery));
};

export const updateWinery = (winery) => {
    return put(`${resourceServer}/wineries`, JSON.stringify(winery))
};

export const deleteWinery = id => {
    return remove(`${resourceServer}/wineries/${id}`)
};

export const getWineryByUrlWithWines = name => {
    return get(`${resourceServer}/wineries:url/${name}?all=true`)
        .then(res => res.data)
};