import React from 'react';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {TextField} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {getTranslation} from "../../../service/translation-service";
import {getTranslationByKey} from "../../../redux/redux-translation-service";

const ControlledSelect = ({title, formControl, disabled, value, onChange, exampleText, name, obj, className, options, ...props}) => {
    return (

        <FormControl variant="outlined" className={(formControl && !formControl.error) ? "fieldContainer valid" : "fieldContainer"}>
            <InputLabel id={`${title}_label`}>{title}</InputLabel>
            <Select
                fullwidth
                labelId={`${title}_label`}
                label={title}
                disabled={disabled}
                error={formControl && formControl.error}
                helperText={formControl && formControl.msg}
                value={value || ''}
                onChange={e => onChange(obj, e.target.name, e.target.value)}
                name={name}
                obj={obj}
                className={`${className} controlledField`}
                {...props}
            >
                {options.map((option, idx) => <MenuItem key={`menu-option-${idx}`}
                                                        value={option}>{getTranslationByKey(option)}</MenuItem>)}
            </Select>
            <div>
                {exampleText && <div style={{width: '30%', fontSize: '.85em'}}>{exampleText}</div>}
            </div>
        </FormControl>
    );
};

export default ControlledSelect;