import React, {useEffect, useState} from 'react';
import {setError, setSuccess} from "../../redux/redux-feedback-service";
import Grid from "@material-ui/core/Grid";
import FloatingBox from "../common/form/floating-box";
import FormActionBar from "../common/form/form-action-bar";
import {withRouter} from "react-router-dom";
import {getPromo, savePromo, updatePromo} from "../../service/promo-service";
import SimpleForm from "../form/single/simple-form";
import {useForm} from "react-hook-form";
import ControlledCheckbox from "../form/base/controlled-checkbox";
import ControlledDatetimePicker from "../form/base/controlled-datetime-picker";
import Backdrop from "@material-ui/core/Backdrop";
import moment from "moment";

function PromoDetail({match}) {
    const [promo, setPromo] = useState(null);
    const [mode, setMode] = useState('');
    const [loading, setLoading] = useState(true);

    const {control, errors, setValue, getValues, watch, handleSubmit, reset, formState} = useForm();

    const start = watch('start');
    const end = watch('end');

    useEffect(() => {
        if (match.params && match.params.id !== 'uj') {
            getPromo(match.params.id).then(res => {
                setPromo(res.data);
                setMode('edit')
            }).finally(() => setLoading(false))
        } else {
            setLoading(false);
            setPromo({});
        }
    }, []);

    const doUpdatePromo = () => {
        doPromoRequest(updatePromo)
    };

    const createPromo = () => {
        doPromoRequest(savePromo)
    };

    const doPromoRequest = method => {
        const p = {
            id: promo.id,
            active: getValues('active'),
            restricted: getValues('restricted') || false,
            percentage: getValues('percentage'),
            end: getValues('end'),
            start: getValues('start'),
            code: getValues('code'),
            limit: getValues('limit') || 0,
        };

        method(p)
            .then(res => {
                setSuccess('Sikeres mentés');
                window.history.back();
            })
            .catch(err => setError('Sikertelen mentés'))
    };

    if (loading) {
        return <div>
            <Backdrop open/>
        </div>
    }

    return (
        <Grid container>
            <Grid xs={12} item>
                <FloatingBox sectionTitle="Kedvezmény kupon">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SimpleForm control={control} errors={errors} required defaultValue={promo.code}
                                                onChange={e => setValue('code', e.target.value, {shouldDirty: true})}
                                                name='code' title="Kód"/>

                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleForm control={control} errors={errors} required
                                                defaultValue={promo.percentage}
                                                onChange={e => setValue('percentage', e.target.value, {shouldDirty: true})}
                                                customRules={{pattern: /[1-9]|[1-9][0-9]|100/ || 'Hibás érték'}}
                                                name='percentage' title="Kedvezmény százalék"/>

                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleForm control={control} errors={errors} defaultValue={promo.limit}
                                                onChange={e => setValue('limit', e.target.value, {shouldDirty: true})}
                                                name='limit' title="Limit"
                                                customRules={{pattern: /[1-9][0-9]*|0/ || 'Hibás érték'}}/>

                                </Grid>
                                <Grid item xs={12}>
                                    <ControlledCheckbox control={control} value={getValues('restricted')}
                                                        title="Csak regisztrált felhasználóknak" name="restricted"
                                                        checked={getValues('restricted')}
                                                        defaultValue={promo.restricted}/>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ControlledDatetimePicker control={control} getValues={getValues} errors={errors}
                                                              setValue={setValue} title="Kezdete" name="start"
                                                              min={null}
                                                              value={start} defaultValue={moment(promo.start)}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ControlledDatetimePicker control={control} errors={errors} setValue={setValue}
                                                              title="Vége" name="end" value={end} min={start}
                                                              defaultValue={moment(promo.end)}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ControlledCheckbox control={control} value={getValues('active')}
                                                        title="Aktív" name="active" defaultValue={promo.active}
                                                        checked={getValues('active')}/>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FloatingBox>
            </Grid>
            <FormActionBar modified={formState.isDirty} reset={reset}
                           update={mode === 'edit' ? handleSubmit(doUpdatePromo) : handleSubmit(createPromo)}/>
        </Grid>
    );
}

export default withRouter(PromoDetail);