import {email} from "./email";

export const contact = {
    firstName: {
        value: '', required: true, regex: {value: /^[A-ZÁÉÍÓŐÚÜŰ][a-záéíöőúű]+$/, errorType: 'alphabetical'}
    },
    lastName: {
        value: '',
        required: true,
        regex: {value: /^[A-ZÁÉÍÓŐÚÜŰ][a-záéíöőúű]+$/, errorType: 'alphabetical'}
    },
    phoneNumber: {
        value: '',
        required: true,
        regex: {value: /^\+[1-9]$|^0[0-9 ]{10,}$/, errorType: 'phoneNumber'}
    },
    contactType: {value: 'INDIVIDUAL', required: true, regex: {value: /INDIVIDUAL|COMPANY/}},
    taxNumber: {value: '', required: false, regex: {value: /^[0-9]{11}$/, errorType: 'taxNumber'}},
    companyName: {
        value: '', required: false,
        regex: {
            value: /^[A-ZÁÉÍÓŐÚÜŰ][a-záéíöőúű0-9 .]+$/,
            errorType: 'companyName'
        }
    },
    created: {value: '', required: false, regex: ''},
    lastModified: {value: '', required: false, regex: ''},
};

export const addressContact = {
    ...contact,
    email
};

export const changeType = (data, dataField, type, formControl) => {
    const contact = data[dataField];
    if (contact.contactType.value === type) {
        return;
    }

    if (type === 'INDIVIDUAL') {
        contact.taxNumber.required = false;
        contact.taxNumber.value = null;
        contact.companyName.required = false;
        contact.companyName.value = null;
        delete formControl[`${dataField}.companyName`];
        delete formControl[`${dataField}.taxNumber`];
        contact.contactType.value = type;
    } else if (type === 'COMPANY') {
        contact.taxNumber.required = true;
        contact.companyName.required = true;
        contact.contactType.value = type;
    }
};