import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Products from "../products/product-container";
import WineryContainer from "../wineries/winery-container";
import Dashboard from "../dashboard/dashboard";
import './nav.css'
import ProductEditor from "../products/product-editor";
import WineryEditor from "../wineries/winery-editor";
import PostContainer from "../blog/post-container";
import PostEditor from "../blog/post-editor";
import Snackbar from "@material-ui/core/Snackbar";
import {setPort, setTranslations} from "../../redux/action";
import {connect} from "react-redux";
import {closeFeedback} from "../../redux/redux-feedback-service";
import Drawer from "@material-ui/core/Drawer";
import {Hidden} from "@material-ui/core";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import NavLink from "./nav-link";
import IconButton from "@material-ui/core/IconButton";
import {FaChevronLeft, FaList} from "react-icons/all";
import SaleDetail from "../sale/sale-detail";
import OrderContainer from "../order/order-container";
import OrderDetailContainer from "../order/order-detail-container";
import UserContainer from "../users/user-container";
import UserDetailContainer from "../users/user-detail-container";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {setConfirmationState} from "../../redux/redux-confirmation-service";
import PromoContainer from "../promo/promo-container";
import PromoDetail from "../promo/promo-detail";
import SaleContainer from "../sale/sale-container";
import TranslationsContainer from "../translation/translations-container";
import TranslationDetail from "../translation/translation-detail";
import SliderDetail from "../slider/slider-detail";
import SliderContainer from "../slider/slider-container";
import ImageBrowserDialog from "../common/file-browser/image-browser-dialog";
import {logout} from "../../service/auth-service";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {getAllTranslations} from "../../service/translation-service";
import PackContainer from "../pack/pack-container";
import PackDetail from "../pack/pack-detail";
import TourContainer from "../tour/tour-container";
import TourDetail from "../tour/tour-detail";

class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerOpen: true,
            handleImageOpen: null,
            browserOpen: false
        }
    }

    componentDidMount() {
        const { doSetTranslation } = this.props;
        getAllTranslations().then(
            res => doSetTranslation(res.data)
        )
    }

    navItems = () => {
        return <div className="drawerContainer">
            <Hidden smUp>
                <IconButton onClick={() => this.setDrawerOpen(false)}>
                    <FaChevronLeft/>
                </IconButton>
            </Hidden>
            <div style={{width: '100%', textAlign: 'center', margin: '.5em 0'}}>
                <img alt='Ikon' style={{width: '75%'}} src={require('../../resource/ikon.png')}/>
            </div>
            <List>
                {[{label: 'Dashboard', to: '/'},
                    {label: 'Felhasználók', to: '/felhasznalok'},
                    {label: 'Rendelések', to: '/rendelesek'}
                ].map((text, index) => (
                    <NavLink key={`${index}-overview-nav-link`} item={text}/>
                ))}
            </List>
            <Divider/>
            <List>
                {[{label: 'Termékek', to: '/termekek'},
                    {label: 'Borászatok', to: '/boraszatok'},
                    {label: 'Blog', to: '/blog'},
                    {label: 'Borcsomagok', to: '/borcsomagok'},
                    {label: 'Akciók', to: '/leertekeles'},
                    {label: 'Nyitóképek', to: '/slider'},
                    {label: 'Programok', to: '/bortura'},
                    {label: 'Kuponok', to: '/kupon'},
                    {label: 'Fordítások', to: '/forditas'},
                ].map((text, index) => (
                    <NavLink key={`${index}-content-nav-link`} item={text}/>
                ))}
            </List>
            <Divider/>
            <ListItem button key="Kép feltöltés" onClick={() => this.setState({browserOpen: true})}>
                <ListItemText primary="Kép feltöltés" primaryTypographyProps={{
                    variant: 'h6'
                }}/>
            </ListItem>
            <List>
                {[{label: 'Kezdőlap', to: '/oldalak/kezdo'},
                    {label: 'Kapcsolat', to: '/oldalak/kapcsolat'}
                ].map((text, index) => (
                    <NavLink key={`${index}-pages-nav-link`} item={text}/>
                ))}
            </List>
        </div>
    };

    setDrawerOpen = drawerOpen => this.setState({drawerOpen});

    render() {
        const {feedbackBarOpen, confirmationState} = this.props;
        const { drawerOpen, browserOpen, handleImageOpen } = this.state;

        return (
            <div>
                <ImageBrowserDialog handleOpen={handleImageOpen} open={browserOpen}
                                    onClose={() => this.setState({browserOpen: false})}/>
                <Snackbar open={feedbackBarOpen.open} autoHideDuration={4000} onClose={closeFeedback}>
                    <div style={{
                        backgroundColor: feedbackBarOpen.color,
                        padding: '1em',
                        width: '60vw',
                        textAlign: 'center',
                        borderRadius: 10
                    }}>
                        {feedbackBarOpen.message}
                    </div>
                </Snackbar>
                <Dialog open={confirmationState.open}>
                    <div style={{padding: '2em', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',  alignItems: 'center', height: 100, width: 500}}>
                        <Typography variant='h6'>{confirmationState.title ? confirmationState.title : 'Biztosan végleg törölni szeretné ezt az elemet?'}</Typography>
                        <div style={{display: 'flex', width: '60%', justifyContent: 'space-around'}}>
                            <Button onClick={() => {
                                confirmationState.callback();
                                setConfirmationState(false, null, '')
                            }} color='primary' variant='contained'>Igen</Button>
                            <Button variant='outlined' onClick={() => setConfirmationState(false, null, '')}>Mégse</Button>
                        </div>
                    </div>
                </Dialog>
                <Router>
                    <AppBar position="fixed" className="appBar" color="primary">
                        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography variant="h6" noWrap>
                                Monavin admin oldal
                            </Typography>
                            <Button variant="text" onClick={() => logout()}>
                                Kijelentkezés
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className="root">
                        <nav>
                            <Hidden mdDown>
                                <Drawer variant="permanent" open={drawerOpen} className="drawer" PaperProps={{
                                    className: "drawerPaper"
                                }}>
                                    {this.navItems()}
                                </Drawer>
                            </Hidden>
                            <Hidden smUp>
                                <Drawer variant="temporary" open={drawerOpen} className="smDrawer" PaperProps={{
                                    className: "smDrawerPaper"
                                }}>
                                    {this.navItems()}
                                </Drawer>
                            </Hidden>
                        </nav>

                        <main className="content">
                            <Switch>
                                <Route path={`/boraszat/:urlPart`} component={WineryEditor}/>
                                <Route path={`/bor/:urlPart`} component={ProductEditor}/>
                                <Route path={`/blog/:urlPart`} component={PostEditor}/>
                                <Route path={`/borcsomag/:urlPart`} component={PackDetail}/>
                                <Route path={`/rendeles/:id`} component={OrderDetailContainer}/>
                                <Route path={`/kupon/:id`} component={PromoDetail}/>
                                <Route path={`/slider/:id`} component={SliderDetail}/>
                                <Route path={`/leertekeles/:id`} component={SaleDetail}/>
                                <Route path={`/felhasznalo/:userId`} component={UserDetailContainer}/>
                                <Route path={`/forditas/:id`} component={TranslationDetail}/>
                                <Route path={`/bortura/:id`} component={TourDetail}/>
                                <Route exact path="/blog/uj-bejegyzes">
                                    <PostEditor/>
                                </Route>
                                <Route exact path="/bor/uj-bor">
                                    <ProductEditor/>
                                </Route>
                                <Route exact path="/boraszat/uj-boraszat">
                                    <WineryEditor/>
                                </Route>
                                <Route exact path="/termekek">
                                    <Products/>
                                </Route>
                                <Route exact path="/rendelesek">
                                    <OrderContainer/>
                                </Route>
                                <Route exact path="/leertekeles">
                                    <SaleContainer/>
                                </Route>
                                <Route exact path="/blog">
                                    <PostContainer/>
                                </Route>
                                <Route exact path="/boraszatok">
                                    <WineryContainer/>
                                </Route>
                                <Route exact path="/felhasznalok">
                                    <UserContainer/>
                                </Route>
                                <Route exact path="/borcsomagok">
                                    <PackContainer/>
                                </Route>
                                <Route exact path="/kupon">
                                    <PromoContainer/>
                                </Route>
                                <Route exact path="/slider">
                                    <SliderContainer/>
                                </Route>
                                <Route exact path="/bortura">
                                    <TourContainer/>
                                </Route>
                                <Route exact path="/forditas">
                                    <TranslationsContainer/>
                                </Route>
                                <Route path="/">
                                    <Dashboard/>
                                </Route>
                            </Switch>
                        </main>
                    </div>
                </Router>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doSetPort: port => dispatch(setPort(port)),
        doSetTranslation: val => dispatch(setTranslations(val)),
    }
};

const mapStateToProps = state => {
    return {loading: state.loading, feedbackBarOpen: state.feedbackBarOpen, confirmationState: state.confirmationState}
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);