import {get, post, put, remove} from "./auth-service";
import {resourceServer} from "../constants";

export const getAllPromo = () => {
    return get(`${resourceServer}/promo`)
};

export const getPromo = id => {
    return get(`${resourceServer}/promo/${id}`)
};

export const savePromo = promo => {
    return post(`${resourceServer}/promo`, promo)
};

export const searchPromo = resource => {
    return post(`${resourceServer}/promo:search`, resource)
};

export const updatePromo = promo => {
    return put(`${resourceServer}/promo`, promo)
};

export const removePromo = id => {
    return remove(`${resourceServer}/promo/${id}`)
};