import React, {Component} from 'react';
import {getThisUser, setVipUser} from "../../service/user-service";
import Grid from "@material-ui/core/Grid";
import FloatingBox from "../common/form/floating-box";
import UserAccountContainer from "./user-account-container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import {formatPrice} from "../../service/formatter";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import {FaChevronRight} from "react-icons/all";
import {getAllOrdersByIds, searchOrders} from "../../service/order-service";
import {withRouter} from "react-router-dom";
import ControlledCheckbox from "../form/base/controlled-checkbox";
import Checkbox from "@material-ui/core/Checkbox";
import FormActionBar from "../common/form/form-action-bar";
import {setError, setSuccess} from "../../redux/redux-feedback-service";

class UserDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            lastOrders: [],
            originalVip: false
        }
    }

    componentDidMount() {
        this.fetchUser()
    }

    fetchUser = () => {
        const {match} = this.props;

        if (match && match.params)
            getThisUser(match.params.userId)
                .then(res => {
                    this.setState({user: res.data, originalVip: res.data.vip}, () => this.getOrders(res.data))
                })
    };

    getOrders = (user) => getAllOrdersByIds(user.orders).then(res => this.setState({lastOrders: res.data}));

    render() {
        const {user, lastOrders, originalVip} = this.state;

        if (!user) {
            return null
        }

        return (<Grid container>
                <FormActionBar update={() => setVipUser(user.id).then(res => {
                    setSuccess('Sikeres mentés');
                    window.history.back();
                }).catch(err => setError('Sikertelen mentés'))} reset={() => {
                    user.vip = originalVip;
                    this.setState({user: {...user}})
                }} modified={originalVip !== user.vip}/>
                <Grid xs={12} item>
                    <FloatingBox sectionTitle="Fiók adatok" style={{height: '90%'}}>
                        <UserAccountContainer name={user.name} phoneNumber={user.phoneNumber} email={user.email}/>
                        <Grid xs={12} item>
                            <label>Vip felhasználó</label>
                            <Checkbox onChange={(e, v) => {
                                user.vip = v;
                                this.setState({user: {...user}})
                            }} color="primary" checked={user.vip}/>
                        </Grid>
                    </FloatingBox>
                </Grid>
                <Grid xs={12} item>
                    <FloatingBox sectionTitle="Rendelések" style={{height: '90%'}}>
                        <List style={{maxHeight: 600, overflowY: 'auto'}}>
                            {lastOrders.map(order =>
                                <ListItem style={{borderBottom: '1px solid #d8d8d8'}}>
                                    <Grid container>
                                        <Grid xs={3} item>
                                            <ListItemText
                                                primary={moment(order.created).format("YYYY. MM. DD. HH:mm")}/>
                                        </Grid>
                                        <Grid xs={3} item>
                                            <ListItemText
                                                primary={formatPrice(order.price)}/>
                                        </Grid>
                                        <Grid xs={3} item>
                                            <ListItemText
                                                primary={`${order.items.reduce((a, n) => a + n.count, 0)} tétel`}/>
                                        </Grid>
                                        <Grid xs={3} item>
                                            <ListItemSecondaryAction>
                                                <Button variant="outlined" size="small"
                                                        onClick={() => this.props.history.push(`/rendeles/${order.id}`)}>
                                                    Tovább
                                                    <FaChevronRight/>
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            )}
                        </List>
                    </FloatingBox>
                </Grid>
            </Grid>

        );
    }
}

export default withRouter(UserDetailContainer);