import React from 'react';
import ControlledInput from "../base/controlled-input";

const SimpleForm = ({control, onChange, title, name, customRules, errors, required, defaultValue = "", type = "text", disabled, multiline, rows, fullWidth}) => {
    return (
        <ControlledInput
            control={control}
            disabled={disabled}
            errors={errors}
            multiline={multiline}
            rows={rows}
            name={name}
            fullWidth={fullWidth}
            onChange={onChange}
            title={title}
            rules={{
                required: {
                    value: required,
                    message: "Ez mező kötelezően kitöltendő"
                },
                ...customRules
            }}
            defaultValue={defaultValue}
            type={type}
        />
    );
};

export default SimpleForm;