import * as _ from 'lodash'
import {alphabetical, alphaNumerical, numerical, percentage} from "./regex";

export const wine = {
    id: {value: '', required: true, regex: ''},
    name: {value: '', required: true, regex: _.cloneDeep(alphabetical)},
    winery: {value: '', required: true, regex: ''},
    alcohol: {value: '', required: true, regex: _.cloneDeep(percentage)},
    acidity: {value: '', required: true, regex: _.cloneDeep(percentage)},
    sugarContent: {value: '', required: true, regex: _.cloneDeep(percentage)},
    grapeType: {value: '', required: true, regex: _.cloneDeep(alphabetical)},
    character: {value: '', required: true, regex: _.cloneDeep(alphabetical)},
    color: {value: '', required: true, regex: ''},
    dryness: {value: '', required: true, regex: ''},
    technology: {value: '', required: true, regex: _.cloneDeep(alphabetical)},
    vineyard: {value: '', required: true, regex: _.cloneDeep(alphabetical)},
    vipPrice: {value: '', required: true, regex: _.cloneDeep(numerical)},
    price: {value: '', required: true, regex: _.cloneDeep(numerical)},
    netPrice: {value: '', required: true, regex: _.cloneDeep(numerical)},
    year: {value: '', required: true, regex: _.cloneDeep(numerical)},
    packaging: {value: '', required: true, regex: _.cloneDeep(alphaNumerical)},
    description: {value: '', required: true, regex: ''},
    image: {value: '', required: true, regex: ''},
    tags: {value: [], required: false, regex: ''},
    relatedProducts: {value: [], required: false, regex: ''},
    sameWineryWines: {value: [], required: false, regex: ''},
    active: {value: false, required: false, regex: ''},
    shortOnStorage: {value: false, required: false, regex: ''},
    ticket: {value: false, required: false, regex: ''},
    created: {value: null, required: false, regex: ''},
    type: {value: "WINE", required: false, regex: ''},
};