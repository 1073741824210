import {get, post, put} from "./auth-service";
import {resourceServer} from "../constants";
import { remove } from './auth-service'

export const getAllOrdersForIds = (ids) => {
    return post(`${resourceServer}/orders`, JSON.stringify(ids))
};

export const recalculateOrder = (prods) => {
    return post(`${resourceServer}/orders:recalculate`, JSON.stringify(prods))
};

export const setNextStatus = orderId => {
    return get(`${resourceServer}/orders:next-status/${orderId}`)
};

export const getOrder = id => {
    return get(`${resourceServer}/orders/${id}`)
};

export const getAllOrders = () => {
    return get(`${resourceServer}/orders`)
};

export const getAllOrdersByIds = ids => {
    return get(`${resourceServer}/orders:all/${ids}`)
};

export const searchOrders = (searchRequest) => {
    return post(`${resourceServer}/orders:search`, JSON.stringify(searchRequest))
};

export const saveOrder = (data, email) => {
    return post(`${resourceServer}/orders/${email}`, JSON.stringify(data))
};

export const updateOrder = (data, notify) => {
    return put(`${resourceServer}/orders?notify=${notify}`, JSON.stringify(data))
};

export const deleteOrder = id => {
    return remove(`${resourceServer}/orders/${id}`)
};