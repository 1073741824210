import React, {useEffect, useMemo, useState} from 'react';
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {getAllOrders} from "../../service/order-service";
import FloatingBox from "../common/form/floating-box";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {FaChevronRight} from "react-icons/all";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {formatPrice} from "../../service/formatter";
import {searchWines} from "../../service/wine-service";
import Switch from "@material-ui/core/Switch";

function Dashboard(props) {
    const [orders, setOrders] = useState([]);
    const [wines, setWines] = useState([]);
    const [wineCount, setWineCount] = useState(0);
    const [orderCountTimeUnit, setOrderCountTimeUnit] = useState('days');
    const [incomeTimeUnit, setIncomeTimeUnit] = useState('days');

    const history = useHistory();

    useEffect(() => {
        getAllOrders().then(res => setOrders(res.data));
        searchWines({page: 0, limit: 5, propertyFilter: {}, sortResource: {field: 'lastModified', order: 'desc'}})
            .then(res => {
                setWines(res.data.content);
                setWineCount(res.data.totalElements)
            });
    }, []);

    const createDayDateList = () => {
        const startOfWeek = moment().subtract(13, 'days');
        const endOfWeek = moment();

        const days = [];
        let day = startOfWeek;

        while (day <= endOfWeek) {
            days.push(day.format("YYYY.MM.DD"));
            day = day.clone().add(1, 'd');
        }

        return days;
    };

    const createMonthDateList = () => {
        const startOfPeriod = moment().subtract(6, 'months');
        const endOfPeriod = moment();

        const months = [];
        let month = startOfPeriod;

        while (month <= endOfPeriod) {
            months.push(month.format("YYYY.MM"));
            month = month.clone().add(1, 'M');
        }

        return months;
    };

    const prepareOrderDensityData = (orders, timeUnit = 'days') => {
        let period = [];
        let data = [];
        if (timeUnit === 'days') {
            period = createDayDateList();
            data = period.map(day => ({name: day, count: 0, income: 0}));
            orders.filter(order => !order.statuses.some(status => ['ONLINE_PAYMENT_FAILED', 'ONLINE_PAYMENT_TIMEOUT', 'ONLINE_PAYMENT_CANCELED'].includes(status.status)))
                .forEach(order => {
                    const created = moment(order.created).format("YYYY.MM.DD");
                    if (period.includes(created)) {
                        const index = data.findIndex(d => d.name === created);
                        data[index].count++;
                        data[index].income += order.price;
                    }
                });
        } else {
            period = createMonthDateList();
            data = period.map(month => ({name: month, count: 0, income: 0}));
            orders.forEach(order => {
                const created = moment(order.created).format("YYYY.MM");
                if (period.includes(created)) {
                    const index = data.findIndex(d => d.name === created);
                    data[index].count++;
                    data[index].income += order.price;
                }
            });
        }

        return data;
    };

    const incomeDensityData = useMemo(() => {
        return prepareOrderDensityData(orders, incomeTimeUnit)
    }, [orders, incomeTimeUnit]);

    const orderCountDensityData = useMemo(() => {
        return prepareOrderDensityData(orders, orderCountTimeUnit)
    }, [orders, orderCountTimeUnit]);

    const getTotalIncome = () => {
        return orders.filter(order => !order.statuses.some(status => ['ONLINE_PAYMENT_FAILED', 'ONLINE_PAYMENT_TIMEOUT', 'ONLINE_PAYMENT_CANCELED'].includes(status.status)))
            .reduce((total, order) => total + order.price, 0)
    };

    return (
        <Grid container>
            <Grid item xs={12} lg={6}>
                <FloatingBox sectionTitle="Bevételek">
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div>Napi</div>
                        <Switch checked={incomeTimeUnit === 'months'} color='primary'
                                onChange={(e, v) => setIncomeTimeUnit(v ? 'months' : 'days')}/>
                        <div>Havi</div>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={incomeDensityData} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                            <defs>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <Tooltip/>
                            <Area type="monotone" dataKey="income" stroke="#82ca9d" fillOpacity={1}
                                  fill="url(#colorPv)"/>
                        </AreaChart>
                    </ResponsiveContainer>
                </FloatingBox>
            </Grid>
            <Grid item xs={12} lg={6}>
                <FloatingBox sectionTitle="Rendelések száma">
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div>Napi</div>
                        <Switch checked={orderCountTimeUnit === 'months'} color='primary' onChange={(e, v) =>
                            setOrderCountTimeUnit(v ? 'months' : 'days')}/>
                        <div>Havi</div>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={orderCountDensityData} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <Tooltip/>
                            <Area type="monotone" dataKey="count" stroke="#8884d8" fillOpacity={1}
                                  fill="url(#colorUv)"/>
                        </AreaChart>
                    </ResponsiveContainer>
                </FloatingBox>
            </Grid>
            <Grid item xs={12} lg={4}>
                <FloatingBox sectionTitle="Legutóbbi rendelések" style={{height: '85%'}}>
                    <List>
                        {
                            orders.sort((a, b) => new Date(b.created) - new Date(a.created)).slice(0, 5).map((order, idx) =>
                                <ListItem key={`${idx}-order-dashboard-item`}
                                          style={{borderBottom: '1px solid #d8d8d8'}}>
                                    <Grid container>
                                        <Grid xs={4} item>
                                            <ListItemText
                                                primary={`${order.billingAddress.name}`}
                                                secondary={moment(order.created).format("YYYY. MM. DD. HH:mm")}/>
                                        </Grid>
                                        <Grid xs={4} item>
                                            <ListItemText
                                                primary={formatPrice(order.price)}
                                                secondary={`${order.items.reduce((a, n) => a + n.count, 0)} tétel`}/>
                                        </Grid>
                                        <Grid xs={4} item>
                                            <ListItemSecondaryAction>
                                                <Button variant="outlined" size="small"
                                                        onClick={() => history.push(`/rendeles/${order.id}`)}>
                                                    Tovább
                                                    <FaChevronRight/>
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            )
                        }
                    </List>
                </FloatingBox>
            </Grid>
            <Grid item xs={12} lg={4}>
                <FloatingBox sectionTitle="Legutóbb módosított borok" style={{height: '85%'}}>
                    <List>
                        {
                            wines.map((wine, idx) =>
                                <ListItem key={`${idx}-wine-dashboard-item`}
                                          style={{borderBottom: '1px solid #d8d8d8'}}>
                                    <Grid container>
                                        <Grid xs={6} item>
                                            <ListItemText
                                                primary={`${wine.name}`}
                                                secondary={moment(wine.lastModified).format("YYYY. MM. DD. HH:mm")}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <ListItemSecondaryAction>
                                                <Button variant="outlined" size="small"
                                                        onClick={() => history.push(`/bor/${wine.urlPart}`)}>
                                                    Tovább
                                                    <FaChevronRight/>
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            )
                        }
                    </List>
                </FloatingBox>
            </Grid>
            <Grid item xs={12} lg={4}>
                <FloatingBox sectionTitle="Összes bevétel" style={{height: '40%'}}>
                    <div style={{textAlign: 'center'}}>
                        <Typography variant="h2">{formatPrice(getTotalIncome())}</Typography>
                    </div>
                </FloatingBox>
                <FloatingBox sectionTitle="Összes termék" style={{height: '40%'}}>
                    <div style={{textAlign: 'center'}}>
                        <Typography variant="h2">{wineCount}</Typography>
                    </div>
                </FloatingBox>
            </Grid>
        </Grid>

    );
}

export default Dashboard;