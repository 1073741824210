import {get, post, put, remove} from "./auth-service";
import {resourceServer} from "../constants";

export const getAllSlides = () => {
    return get(`${resourceServer}/sliders`)
};

export const getSlide = id => {
    return get(`${resourceServer}/sliders/${id}`)
};

export const saveSlide = slide => {
    return post(`${resourceServer}/sliders`, slide)
};

export const searchSlides = resource => {
    return post(`${resourceServer}/sliders:search`, resource)
};

export const updateSlide = slide => {
    return put(`${resourceServer}/sliders`, slide)
};

export const removeSlide = id => {
    return remove(`${resourceServer}/sliders/${id}`)
};