import React from 'react';
import WineItem from "./wine-item";
import PackItem from "./pack-item";

const ProductItem = ({product, ...props}) => {
    if (product.type === 'WINE') {
        return <WineItem wine={product} {...props} />
    } else if (product.type === 'PACK') {
        return <PackItem pack={product} {...props} />
    }
};

export default ProductItem;