import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {getPromo, savePromo, updatePromo} from "../../service/promo-service";
import {setError, setSuccess} from "../../redux/redux-feedback-service";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import FloatingBox from "../common/form/floating-box";
import SimpleForm from "../form/single/simple-form";
import ControlledCheckbox from "../form/base/controlled-checkbox";
import ControlledDatetimePicker from "../form/base/controlled-datetime-picker";
import moment from "moment";
import FormActionBar from "../common/form/form-action-bar";
import {getTranslation, saveTranslation, updateTranslation} from "../../service/translation-service";

function TranslationDetail({match}) {
    const [translation, setTranslation] = useState(null);
    const [mode, setMode] = useState('');
    const [loading, setLoading] = useState(true);

    const {control, errors, setValue, getValues, watch, handleSubmit, reset, formState} = useForm();

    useEffect(() => {
        if (match.params && match.params.id !== 'uj') {
            getTranslation(match.params.id).then(res => {
                setTranslation(res.data);
                setMode('edit')
            }).finally(() => setLoading(false))
        } else {
            setLoading(false);
            setTranslation({});
        }
    }, [match.params]);

    const doUpdateTranslation = () => {
        doTranslationRequest(updateTranslation)
    };

    const createTranslation = () => {
        doTranslationRequest(saveTranslation)
    };

    const doTranslationRequest = method => {
        const p = {
            id: translation.id,
            key: getValues('key'),
            hungarian: getValues('hungarian'),
            english: getValues('english'),
        };

        method(p)
            .then(res => {
                setSuccess('Sikeres mentés');
                window.history.back();
            })
            .catch(err => setError('Sikertelen mentés'))
    };

    if (loading) {
        return <div>
            <Backdrop open/>
        </div>
    }

    return (
        <Grid container>
            <Grid xs={12} item>
                <FloatingBox sectionTitle="Fordítás">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SimpleForm control={control} errors={errors} required defaultValue={translation.key}
                                        onChange={e => setValue('key', e.target.value, {shouldDirty: true})}
                                        name='key' title="Kulcs"/>
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleForm control={control} errors={errors} required defaultValue={translation.hungarian}
                                        onChange={e => setValue('hungarian', e.target.value, {shouldDirty: true})}
                                        name='hungarian' title="Magyar"/>
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleForm control={control} errors={errors} required defaultValue={translation.english}
                                        onChange={e => setValue('english', e.target.value, {shouldDirty: true})}
                                        name='english' title="Angol"/>
                        </Grid>
                    </Grid>
                </FloatingBox>
            </Grid>
            <FormActionBar modified={formState.isDirty} reset={reset}
                           update={mode === 'edit' ? handleSubmit(doUpdateTranslation) : handleSubmit(createTranslation)}/>
        </Grid>
    );
}

export default TranslationDetail;