import React from 'react';
import PageableContainer from "../common/table/pageable-container";
import {withRouter} from "react-router-dom";
import {deletePack, searchPacks} from "../../service/pack-service";

const sortOptions = [
    {label: 'Név A-Z', field: 'name', order: 'asc'},
    {label: 'Név Z-A', field: 'name', order: 'desc'},
    {label: 'Borászat A-Z', field: 'winery', order: 'asc'},
    {label: 'Borászat Z-A', field: 'winery', order: 'desc'}
];


const PackContainer = () => {
    const getPacksData = packs => {
        return packs.map(pack => ({
            Kép: {src: pack.image},
            Aktív: pack.active,
            Név: pack.name,
        }));
    };

    return (<PageableContainer sortOptions={sortOptions} fetchMethod={searchPacks} urlPrefix="borcsomag" urlParam={'urlPart'}
                               regexFilter={{key: 'name', label: 'Névre szűrés'}}
                               pageableDataMethod={getPacksData} deleteMethod={deletePack}/>)
};

export default withRouter(PackContainer);