import React from 'react';
import Button from "@material-ui/core/Button";
import {FaArrowLeft} from "react-icons/all";
import {Box} from "@material-ui/core";

function FormActionBar({modified, reset, update}) {
    return (
        <Box style={{
            right: 0, padding: 10, zIndex: 100, width: '100vw', display: 'flex', justifyContent: 'flex-end',
            height: '2.5em', backgroundColor: 'rgb(217,217,217)', position: 'fixed', bottom: 0,
        }}>
            <Box style={{width: '85%', display: 'flex', justifyContent: 'flex-end'}}>
                {/*<Button variant="outlined" onClick={() => window.history.back()}>*/}
                {/*    <FaArrowLeft style={{color: '#0c062b'}}>Vissza</FaArrowLeft>*/}
                {/*</Button>*/}
                <div style={{display: 'flex'}}>
                    <Button variant="contained" disabled={!modified} style={{
                        color: 'white',
                        opacity: modified ? 1 : 0.25,
                        backgroundColor: '#5c0e03',
                        marginRight: 5,
                        transition: '.3s',
                        width: '10em'
                    }} onClick={reset}>Visszaállítás</Button>
                    <Button variant="contained" color="primary" disabled={!modified} style={{
                        opacity: modified ? 1 : 0.25,
                        transition: '.5s',
                        width: '10em'
                    }} onClick={update}>Mentés</Button>
                </div>
            </Box>
        </Box>
    );
}

export default FormActionBar;