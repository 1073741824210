import React from 'react';
import PageableContainer from "../common/table/pageable-container";
import {deleteWine, searchWines} from "../../service/wine-service";
import {deletePost, searchPosts} from "../../service/post-service";
import moment from "moment";

const sortOptions = [
    {label: 'Létrehozás növekvő', field: 'created', order: 'asc'},
    {label: 'Létrehozás csökkenő', field: 'created', order: 'desc'},
    {label: 'Cím A-Z', field: 'name', order: 'asc'},
    {label: 'Cím Z-A', field: 'name', order: 'desc'}
];
class PostContainer extends React.Component {

    getPageableData = posts => {
        return posts.map(post => ({
            'Utoljára szerkesztve': moment(post.lastModified).format('YYYY. MM. DD. hh:mm'),
            Cím: post.name,
            Létrehozva: moment(post.created).format('YYYY. MM. DD. hh:mm'),
        }));
    };

    render() {
        return (<PageableContainer sortOptions={sortOptions} fetchMethod={searchPosts} urlPrefix="blog" urlParam={'urlPart'}
                                   pageableDataMethod={this.getPageableData} deleteMethod={deletePost} regexFilter={{key: 'name', label: 'Névre szűrés'}}/>)
    }
}

export default PostContainer;