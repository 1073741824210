import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {withRouter} from "react-router-dom";
import {getSale, saveSale, updateSale} from "../../service/sale-service";
import Backdrop from "@material-ui/core/Backdrop";
import {setError, setSuccess} from "../../redux/redux-feedback-service";
import Grid from "@material-ui/core/Grid";
import FloatingBox from "../common/form/floating-box";
import SimpleForm from "../form/single/simple-form";
import ControlledCheckbox from "../form/base/controlled-checkbox";
import ControlledDatetimePicker from "../form/base/controlled-datetime-picker";
import moment from "moment";
import FormActionBar from "../common/form/form-action-bar";
import ProductPicker from "../common/wine-picker/product-picker";
import TextField from "@material-ui/core/TextField";
import {getProductsByIds} from "../../service/wine-service";
import {Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import * as _ from 'lodash'
import ProductItem from "../common/product/product-item";

function SaleDetail({match}) {
    const [sale, setSale] = useState({items: []});
    const [mode, setMode] = useState('');
    const [prevItems, setPrevItems] = useState([]);
    const [initialItems, setInitialItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const {setValue, errors, control, getValues, watch, formState, handleSubmit, reset} = useForm();

    const start = watch('start');
    const end = watch('end');
    const grouped = watch('grouped') || sale.grouped;
    const percentage = watch('percentage');

    useEffect(() => {
        if (match.params && match.params.id !== 'uj') {
            getSale(match.params.id).then(async res => {
                const wines = await getProductsByIds(res.data.items.map(item => ({productId: item.productId, type: item.type})));
                res.data.items = res.data.items.map(item => ({
                    wine: wines.find(wine => wine.id === item.productId),
                    discount: wines.find(wine => wine.id === item.productId).price - item.discount,
                    percentage: item.percentage
                }));
                setSale(res.data);
                setInitialItems(res.data.items);
                setMode('edit');
            }).finally(() => setLoading(false))
        } else {
            setLoading(false);
            setSale({items: []});
        }
    }, []);

    // useEffect(() => {
    //     if (sale.items) {
    //         setPrevItems([...sale.items]);
    //     }
    // }, [sale.items]);

    const setPricesAuto = (percentageVal) => {
        percentageVal = percentageVal ? percentageVal : percentage;
        if (!sale || !percentageVal) {
            return
        }

        sale.items.forEach((item, idx) => {
            if (grouped) {
                setValue(`${idx}-percentage`, percentageVal);
                setValue(`${idx}-price`, Math.round(item.wine.price - (item.wine.price * percentageVal / 100)));
            } else {
                setValue(`${idx}-percentage`, 0);
                setValue(`${idx}-price`, item.wine.price);
            }
        })
    };

    const doUpdateSale = () => {
        doSaleRequest(updateSale)
    };

    const createSale = () => {
        doSaleRequest(saveSale)
    };

    const doSaleRequest = method => {
        const s = {
            id: sale.id,
            items: sale.items.map((item, idx) => ({
                productId: item.wine.id,
                type: item.wine.type,
                discount: item.wine.price - getValues(`${idx}-price`),
                percentage: getValues(`${idx}-percentage`)
            })),
            active: getValues('active'),
            grouped: getValues('grouped') || false,
            percentage: getValues('percentage'),
            end: getValues('end'),
            start: getValues('start'),
        };

        method(s)
            .then(res => {
                setSuccess('Sikeres mentés');
                window.history.back();
            })
            .catch(err => setError('Sikertelen mentés'))
    };

    if (loading) {
        return <div>
            <Backdrop open/>
        </div>
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <FloatingBox sectionTitle="Leértékelés">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SimpleForm control={control} errors={errors} required={grouped} disabled={!grouped}
                                        defaultValue={sale.percentage} onChange={(e) => {
                                setValue('percentage', e.target.value, {shouldDirty: true});
                                if (grouped) {
                                    setPricesAuto(e.target.value)
                                }
                            }}
                                        customRules={{pattern: /[1-9]|[1-9][0-9]|100/ || 'Hibás érték'}}
                                        name='percentage' title="Akció százalék"/>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledDatetimePicker control={control} getValues={getValues} errors={errors}
                                                      setValue={setValue} title="Kezdete" name="start"
                                                      min={null}
                                                      value={start} defaultValue={moment(sale.start)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledDatetimePicker control={control} errors={errors} setValue={setValue}
                                                      title="Vége" name="end" value={end} min={start}
                                                      defaultValue={moment(sale.end)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledCheckbox control={control} value={getValues('active')}
                                                title="Aktív" name="active" defaultValue={sale.active}
                                                checked={getValues('active')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledCheckbox control={control} value={grouped} customOnChange={() => {
                                if (grouped) {
                                    setPricesAuto();
                                }
                            }}
                                                title="Csoportos leértékelés" name="grouped"
                                                defaultValue={sale.grouped}/>
                        </Grid>
                    </Grid>
                </FloatingBox>
            </Grid>
            <Grid item xs={12} md={6}>
                <FloatingBox sectionTitle="Bor lista">
                    <Grid item xs={12}>
                        <ProductPicker onChange={(e, v) => {
                            if (!v) {
                                return
                            }
                            const items = [...sale.items, {wine: v}];
                            setSale({...sale, items});
                            if (grouped) {
                                setValue(`${items.length - 1}-percentage`, percentage);
                                setValue(`${items.length - 1}-price`, Math.round(v.price - (v.price * percentage / 100)));
                            }
                        }} exclusions={{id: sale.items.map(item => item.wine.id)}}/>
                        <div style={{maxHeight: 500, overflowY: 'scroll'}}>
                            {sale.items.map((i, idx) => {
                                    const val = watch([`${idx}-percentage`, `${idx}-price`]);
                                    return <div key={`sale-item-${idx}`}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                        <ProductItem product={i.wine}/>
                                        <SimpleForm control={control} errors={errors} required disabled={grouped}
                                                    defaultValue={i.percentage}
                                                    onChange={e => {
                                                        setValue(`${idx}-percentage`, e.target.value, {shouldDirty: true});
                                                        setValue(`${idx}-price`, Math.round(i.wine.price - (i.wine.price * e.target.value / 100)), {shouldDirty: true})

                                                    }}
                                                    customRules={{
                                                        pattern: /[1-9]|[1-9][0-9]|100/ || 'Hibás érték',
                                                        max: 100 || 'Hibás érték',
                                                        min: 0 || 'Hibás érték'
                                                    }}
                                                    name={`${idx}-percentage`} title="Százalék"/>
                                        <div style={{margin: '0 1em'}}>
                                            <SimpleForm control={control} errors={errors} required disabled={grouped}
                                                        defaultValue={i.discount}
                                                        onChange={e => {
                                                            setValue(`${idx}-price`, e.target.value, {shouldDirty: true});
                                                            setValue(`${idx}-percentage`, Math.round(100 - (e.target.value / i.wine.price * 100)), {shouldDirty: true})
                                                        }}
                                                        customRules={{
                                                            pattern: /[1-9][0-9]*/ || 'Hibás érték',
                                                            max: i.wine.price || 'Hibás érték',
                                                            min: 0 || 'Hibás érték'
                                                        }}
                                                        name={`${idx}-price`} title="Akciós ár"/>
                                        </div>
                                        <TextField disabled value={i.wine ? i.wine.price : 0} label="Eredeti ár"
                                                   variant="outlined"/>
                                        <IconButton onClick={() => {
                                            sale.items.splice(idx, 1);
                                            setSale({...sale});
                                        }}>
                                            <Close/>
                                        </IconButton>
                                    </div>
                                }
                            )}
                        </div>
                    </Grid>
                </FloatingBox>
            </Grid>
            <FormActionBar
                modified={formState.isDirty || !_.isEqual(initialItems, sale.items)}
                reset={reset}
                update={mode === 'edit' ? handleSubmit(doUpdateSale) : handleSubmit(createSale)
                }
            />
        </Grid>
    );
}

export default withRouter(SaleDetail);