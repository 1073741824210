import React from 'react';
import {Controller} from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";

function ControlledCheckbox({control, title, name, value, defaultValue, customOnChange}) {
    return <div>
        <label>{title}</label>
        <Controller
            render={({onChange, onBlur, value, name}) => (
                <Checkbox
                    onBlur={onBlur}
                    onChange={e => {
                        onChange(e.target.checked);
                        customOnChange && customOnChange();
                    }}
                    checked={value || false}
                    name={name}
                    color="primary"
                />
            )}
            control={control}
            name={name}
            value={value || false}
            defaultValue={defaultValue}
        />
    </div>
}

export default ControlledCheckbox;