import React, {Component} from 'react';
import {TableBody, TableCell, TableContainer, Tooltip} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import {FaEdit, FaTrash} from "react-icons/all";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import Button from "@material-ui/core/Button";
import TableHead from "@material-ui/core/TableHead";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

function PageableTable({data, withoutHeader, onDelete, onEdit, page, totalPages, limit, turnPage, totalElements, sort, setRegex, regexMap, setSort, onCreate, sortOptions, filter, withoutCreation}) {
    const renderTableBody = (data, headers) => {
        return <TableBody>
            {data.map((row, idx) => <TableRow key={`${idx}_pageable_table_row`}>
                {Object.keys(row).map((col, ind) => <TableCell key={`${ind}_body_cell`}
                                                               style={{width: `${100 / headers.length + 1}%`}}
                                                               align="center">
                        {typeof row[col] === 'boolean' ?
                            <Checkbox checked={row[col]} disabled/> : (typeof row[col] !== 'object' || row[col] === null) ?
                                <Typography variant="h6"> {row[col]}</Typography> :
                                <img style={{width: '30%'}} src={row[col].src}/>}
                    </TableCell>
                )}
                <TableCell align="center">
                    <Tooltip title="Szerkesztés">
                        <IconButton onClick={() => onEdit(idx)} size="medium">
                            <FaEdit color='#111732'/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title="Törlés">
                        <IconButton onClick={() => onDelete(idx)} size="medium">
                            <FaTrash color='#111732'/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>)
            }
        </TableBody>
    };

    const headers = data.length ? Object.keys(data[0]) : [];

    const renderPagination = () => {
        const firstItem = totalPages ? (page - 1) * limit + 1 : 0;
        const finalItem = page * limit > totalElements ? totalElements : page * limit;
        return <Grid container alignItems="center" style={{
            display: 'flex',
            padding: '1.5em 0',
            backgroundColor: '#f4f4f4'
        }}>
            <Grid item xs={4}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                    <Pagination count={totalPages} page={page} onChange={turnPage}/>
                    <div style={{fontSize: '1.1em'}}>{`Tételek: ${firstItem}-${finalItem}`}</div>
                </div>
            </Grid>
            <Grid item xs={3} style={{textAlign: 'center'}}>
                <Select
                    variant="filled"
                    value={sort.label || ""}
                    onChange={setSort}
                    name="sort"
                    label="Rendezés"
                >
                    {sortOptions.map(s => s.label)
                        .map((item, idx) => <MenuItem key={`menu-sorting-${idx}`} value={item}>{item}</MenuItem>)}
                </Select>
            </Grid>
            {filter && <Grid item xs={3} style={{textAlign: 'center'}}>
                <TextField variant="filled" label={filter.label}
                           onChange={({target: {name, value}}) => setRegex(filter.key, value)}
                           value={regexMap[filter.key]}/>
            </Grid>}
            {!withoutCreation && <Grid item xs={2} style={{textAlign: 'center'}}>
                <Button variant="contained" style={{width: '75%'}} onClick={onCreate} color='primary'>
                    Új elem
                </Button>
            </Grid>}
        </Grid>
    };

    return (
        <div>
            {!withoutHeader && renderPagination()}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, idx) => <TableCell key={`${idx}_header`} align="center">
                                <Typography style={{fontWeight: 'bold'}} variant="h5">{header}</Typography>
                            </TableCell>)}
                            {headers.length ? <TableCell colSpan={2}>
                                <Typography style={{fontWeight: 'bold'}} variant="h5">Műveletek</Typography>
                            </TableCell> : null}
                        </TableRow>
                    </TableHead>
                    {data.length ? renderTableBody(data, headers) :
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Typography style={{fontWeight: 'bold', textAlign: 'center', margin: '3em 0'}}
                                                variant="h5">Nincs megjelenítendő adat</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            {!withoutHeader && renderPagination()}
        </div>
    );
}

export default PageableTable;