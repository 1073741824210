import {getAccessToken, logout} from "../service/auth-service";
import {store} from "./store";
import {authenticate, signOut} from "./action";

export let authProcess = null;

export const doLogout = () => {
    logout();
    clearTimeout(authProcess);
    store.dispatch(signOut());
};

export const accessRequestSuccess = s => {
    store.dispatch(authenticate(s.data.username));
    authProcess = setTimeout(getAccessToken, 1000 * 60 * 10);
};