import React, {useState} from 'react';
import {TextField} from "@material-ui/core";
import {Controller} from "react-hook-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";

function ControlledInput({control, errors, title, name, defaultValue, onChange, type, rules, disabled, multiline, rows, fullWidth, value}) {
    const [visible, setVisible] = useState(false);

    return <Controller
        render={({onChangeDef, onBlur, value, name}) => (
            <TextField
                label={title}
                onChange={e => {onChange(e)}}
                type={type === 'password' && visible ? 'text' : type}
                helperText={errors[name] && errors[name].message}
                variant="outlined"
                disabled={disabled}
                value={value}
                multiline={multiline}
                fullWidth={fullWidth}
                rows={rows}
                InputProps={{
                    endAdornment: type === 'password' &&
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setVisible(!visible)}
                                onMouseDown={e => e.preventDefault()}
                                edge="end"
                                size="small"
                            >
                                {visible ? <Visibility/> : <VisibilityOff color='primary'/>}
                            </IconButton>
                        </InputAdornment>
                }}
            />
        )}
        control={control}
        rules={rules}
        name={name}
        defaultValue={defaultValue}
        error={errors[name]}
        value={value}
    />
}

export default ControlledInput;