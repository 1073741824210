import {get, post, put, remove} from "./auth-service";
import {resourceServer} from "../constants";

export const getAllPosts = () => {
    return get(`${resourceServer}/posts`)
        .then(res => res.data)
};

export const getPostByUrl = url => {
    return get(`${resourceServer}/posts:url/${url}`)
        .then(res => res.data)
};

export const createPost = (postObj) => {
    return post(`${resourceServer}/posts`, JSON.stringify(postObj))
};

export const updatePost = post => {
    return put(`${resourceServer}/posts`, JSON.stringify(post))
};

export const deletePost = id => {
    return remove(`${resourceServer}/posts/${id}`)
};

export const searchPosts = (searchMap) => {
    return post(`${resourceServer}/posts:search`, JSON.stringify(searchMap))
};