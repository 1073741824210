import React, {useEffect, useState} from 'react';
import 'chonky/style/main.css';
import {ChonkyActions, FileBrowser, FileHelper, FileList, FileSearch, FileToolbar,} from 'chonky';
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {resourceServer} from "../../../constants";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";

const ImageBrowser = ({handleOpen, onClose, imageTree, save, remove, move, makeDir}) => {
    const [currentFolderId, setCurrentFolderId] = useState("");
    const [type, setType] = useState(null);

    useEffect(() => setCurrentFolderId(imageTree.rootFolderId), [imageTree.rootFolderId]);
    useEffect(() => setType(null), []);

    const handleCapture = ({target}) => {
        if (!target || !target.files[0]) {
            return
        }
        const folder = imageTree.fileMap[currentFolderId];
        const fileReader = new FileReader();

        const path = folder.path ? folder.path + '/' + target.files[0].name : target.files[0].name;
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            const file = new FormData();
            file.append('file', target.files[0]);
            save(file, path, type)
        };
    };

    const handleMove = (target, files) => {
        const paths = files.map(f => f.path);
        move(target.path, paths)
    };

    const handleDelete = files => {
        const paths = files.map(f => f.path);
        remove(paths);
    };

    const handleMkDir = () => {
        const current = imageTree.fileMap[currentFolderId];
        const name = prompt("A létrehozandó könyvtár neve");

        if (!name) {
            return;
        }

        makeDir(current.path + '/' + name)
    };

    const useFiles = () => {
        const currentFolder = imageTree.fileMap[currentFolderId || imageTree.rootFolderId];
        const Files = !currentFolder.childrenIds
            ? []
            : currentFolder.childrenIds.map((fileId) => {
                const file = imageTree.fileMap[fileId];
                return file ? file : null;
            });
        return Files;
    };

    const useFolderChain = () => {
        const currentFolder = imageTree.fileMap[currentFolderId || imageTree.rootFolderId];

        const folderChain = [currentFolder];

        let parentId = currentFolder.parentId;
        while (parentId) {
            const parentFile = imageTree.fileMap[parentId];
            if (parentFile) {
                folderChain.unshift(parentFile);
                parentId = parentFile.parentId;
            } else {
                parentId = null;
            }
        }

        return folderChain;
    };

    const handleFileAction = (action, actionData) => {
        if (action.id === ChonkyActions.OpenFiles.id) {
            let targetFile = null;
            if (actionData.target) {
                targetFile = actionData.target;
            } else if (actionData.files && actionData.files.length === 1) {
                targetFile = actionData.files[0];
            }

            if (targetFile && FileHelper.isDirectory(targetFile)) {
                setCurrentFolderId(targetFile.id);
                return;
            }
            if (targetFile && FileHelper.isOpenable(targetFile)) {
                handleOpen(resourceServer + '/images?path=' + targetFile.path);
            }
        } else if (action.id === ChonkyActions.UploadFiles.id) {
            document.getElementById("dialog-input").click();
        } else if (action.id === ChonkyActions.MoveFilesTo.id) {
            handleMove(actionData.target, actionData.files)
        } else if (action.id === ChonkyActions.DeleteFiles.id) {
            handleDelete(actionData.files)
        } else if (action.id === ChonkyActions.CreateFolder.id) {
            handleMkDir();
        }

    };

    const thumbnailGenerator = (file) => {
        if (file.isDir) {
            return;
        }
        return Promise.resolve()
            .then(() => resourceServer + '/images?path=' + file.path)
    };

    const renderFiles = useFiles(currentFolderId);
    const folderChain = useFolderChain(currentFolderId);

    if (!renderFiles || !folderChain) {
        return null;
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <input id="dialog-input" type="file" onChange={handleCapture} style={{"display": "none"}}/>
            <FileBrowser
                thumbnailGenerator={thumbnailGenerator}
                files={renderFiles}
                fileActions={type ? [
                    ChonkyActions.UploadFiles,
                    ChonkyActions.CreateFolder,
                    ChonkyActions.DeleteFiles
                ] : [
                    ChonkyActions.CreateFolder,
                    ChonkyActions.DeleteFiles
                ]}
                enableDragAndDrop={true}
                folderChain={folderChain}
                onFileAction={handleFileAction}
            >
                <FileToolbar/>
                <FileSearch/>
                <FileList/>
            </FileBrowser>
            <Grid container style={{margin: '2em 0 1em 1em', width: '95%'}}>
                <Grid xs={12} md={8}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Feltöltendő kép optimalizálása</FormLabel>
                        <RadioGroup aria-label="imageType" row name="type" value={type}
                                    onChange={e => setType(e.target.value)}>
                            <FormControlLabel value="WINE" control={<Radio/>} label="Bor kép"/>
                            <FormControlLabel value="ENOLOGIST" control={<Radio/>} label="Borász arckép"/>
                            <FormControlLabel value="WINERY_PARAGRAPH" control={<Radio/>} label="Borászat leírás kép, vagy program kép"/>
                            <FormControlLabel value="WINERY_LOGO" control={<Radio/>} label="Borászat logó kép"/>
                            <FormControlLabel value="COVER" control={<Radio/>} label="Slider, blog, vagy borászat borító kép"/>
                            <FormControlLabel value="NONE" control={<Radio/>} label="Nincs optimalizálás"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button onClick={onClose} style={{float: 'right'}} variant="outlined">Bezárás</Button>
                </Grid>
            </Grid>
        </DndProvider>
    );
};

export default ImageBrowser;