import React from 'react';
import ControlledInput from "../base/controlled-input";
import SimpleForm from "./simple-form";

const TelephoneForm = ({control, disabled, onChange, errors, defaultValue = ""}) => {
    return (
        <ControlledInput
            control={control}
            errors={errors}
            name="phoneNumber"
            onChange={onChange}
            disabled={disabled}
            title="Telefonszám"
            rules={{
                required: "Ez a mező kötelezően kitöltendő",
                pattern: {
                    value: /[0-9-+/ ]{9,}/,
                    message: "Érvénytelen telefonszám formátum"
                }
            }}
            defaultValue={defaultValue}
            type="tel"
        />
    );
};

export default TelephoneForm;