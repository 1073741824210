export function authenticate(payload) {
    return { type: "AUTHENTICATE", payload }
};

export function signOut(payload) {
    return { type: "LOGOUT", payload }
};

export function setLocale(payload) {
    return { type: "LANG", payload }
};

export function setLoading(payload) {
    return { type: "LOADING", payload }
};

export function setConfirmation(payload) {
    return { type: "CONFIRMATION", payload }
};

export function setPort(payload) {
    return { type: "PORT", payload }
};

export function setFeedbackBar(payload) {
    return { type: "FEEDBACK_BAR_OPEN", payload }
};

export function setTranslations(payload) {
    return { type: "TRANSLATIONS", payload }
};