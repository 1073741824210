import React from 'react';
import PageableContainer from "../common/table/pageable-container";
import {removeSlide, searchSlides} from "../../service/slider-service";
import {searchTours} from "../../service/tour-service";
import moment from 'moment'

const sortOptions = [
    {label: 'Kezdet növekvő', field: 'start', order: 'asc'},
    {label: 'Kezdet csökkenő', field: 'start', order: 'desc'},
    {label: 'Vége növekvő', field: 'end', order: 'asc'},
    {label: 'Vége csökkenő', field: 'end', order: 'desc'},
];

function TourContainer(props) {
    const getTours = tours => {
        return tours.map(tour => ({
            Kép: {src: tour.image},
            Aktív: tour.active,
            Név: tour.name,
            Kezdete: moment(tour.start).format("YYYY. MM. DD"),
            Vége: moment(tour.end).format("YYYY. MM. DD"),
        }));
    };

    return (
        <PageableContainer sortOptions={sortOptions} fetchMethod={searchTours} urlPrefix="bortura"
                           pageableDataMethod={getTours} deleteMethod={removeSlide} urlParam={'id'}/>
    );
}

export default TourContainer;