import React from 'react';
import './product-editor.css'
import * as PropTypes from "prop-types";
import * as _ from "lodash";
import moment from "moment";
import "moment/locale/hu";
import FormActionBar from "../common/form/form-action-bar";
import {createWine, getAllWines, getWineByUrlPart, updateWine} from "../../service/wine-service";
import {getAllWineries} from "../../service/winery-service";
import FloatingBox from "../common/form/floating-box";
import ControlledField from "../common/form/controlled-field";
import ControlledSelect from "../common/form/controlled-select";
import {wine} from "../../model/wine";
import {withFormControl} from "../../hoc/form-controller";
import {setError, setSuccess} from "../../redux/redux-feedback-service";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import ImageBrowserDialog from "../common/file-browser/image-browser-dialog";
import ProductPicker from "../common/wine-picker/product-picker";
import ImageUpload from "../common/form/image-upload";
import ProductItem from "../common/product/product-item";
import {getAllPacks} from "../../service/pack-service";

moment.locale("hu");

class ProductEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectImage: false,
            wineries: [],
            wines: [],
            mode: '',
            selectedImage: '',
            browserOpen: false,
            loading: true
        }
    }

    componentDidMount() {
        const {match, updateData, setData} = this.props;
        getAllWines().then(res => {
            this.setState({wines: res});
            getAllPacks().then(res => this.setState(prev => {
                const wines = prev.wines;
                res.forEach(item => wines.push(item));
                return {wines, loading: false}
            }))
        });

        if (match.params && match.params.urlPart !== 'uj') {
            getWineByUrlPart(match.params.urlPart).then(res => updateData(res, 'wine'));
            this.setState({mode: 'edit'})
        } else {
            setData({wine: _.cloneDeep(wine)});
            this.setState({mode: 'create'})
        }

        getAllWineries().then(res => this.setState({wineries: res}));
    }

    updateWine = () => {
        this.doWineRequest(updateWine)
    };

    createWine = () => {
        this.doWineRequest(createWine)
    };

    doWineRequest = method => {
        const {wineries} = this.state;
        const {data} = this.props;
        const wine = {};

        Object.keys(data.wine).forEach(k => wine[k] = data.wine[k].value);
        wine.winery = wineries.find(w => w.name === wine.winery).id;

        method(wine)
            .then(res => {
                setSuccess('Sikeres mentés');
                window.history.back();
            })
            .catch(err => setError('Sikertelen mentés'))
    };

    setPrices = (obj, name, value) => {
        const {changeProperty} = this.props;
        if (name === 'price') {
            changeProperty(obj, 'netPrice', (Math.round((value / 1.27))))
        } else if (name === 'netPrice') {
            changeProperty(obj, 'price', (Math.round((value * 1.27))))
        }
        changeProperty(obj, name, value)
    };

    handleWinePick = (event, value, field) => {
        if (!value) {
            return
        }

        const {changeProperty, data} = this.props;

        const wineArr = data.wine[field].value;

        wineArr.push(value.id);
        changeProperty('wine', field, wineArr);
        this.setState({modified: true})
    };

    render() {
        const {wineries, mode, browserOpen, wines, loading} = this.state;
        const {data, formControl, modified, changeProperty, resetData} = this.props;
        const {wine} = data;

        if (!wineries.length || !wine || loading) {
            return null;
        }

        return (
            <div>
                <ImageBrowserDialog handleOpen={(path) => {
                    changeProperty('wine', 'image', path);
                    this.setState({browserOpen: false})
                }} open={browserOpen} onClose={() => this.setState({browserOpen: false})}/>
                <FormActionBar reset={resetData} modified={modified && wine.name.value && wine.year.value && wine.winery.value}
                               update={mode === 'edit' ? this.updateWine : this.createWine}/>
                <Grid container alignContent="stretch">
                    <Grid xs={12} md={6} item>
                        <FloatingBox sectionTitle="Fő tulajdonságok" style={{height: '90%'}}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '30%',
                                justifyContent: 'space-between'
                            }}>
                                <Typography variant="h6">Aktív</Typography>
                                <Checkbox color="primary" checked={wine.active.value}
                                          onChange={(e, v) => changeProperty('wine', 'active', v)}/>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '30%',
                                justifyContent: 'space-between'
                            }}>
                                <Typography variant="h6">Esemény jegy</Typography>
                                <Checkbox color="primary" checked={wine.ticket.value}
                                          onChange={(e, v) => changeProperty('wine', 'ticket', v)}/>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '30%',
                                justifyContent: 'space-between'
                            }}>
                                <Typography variant="h6">Kevés a készleten</Typography>
                                <Checkbox color="primary" checked={wine.shortOnStorage.value}
                                          onChange={(e, v) => changeProperty('wine', 'shortOnStorage', v)}/>
                            </div>
                            <ControlledField title="Név"
                                             value={wine.name.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.name']}
                                             name="name" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledSelect
                                title="Borászat"
                                value={wine.winery.value}
                                onChange={changeProperty}
                                formControl={formControl['wine.winery']}
                                name="winery" obj="wine" exampleText=""
                                className=""
                                options={wineries.map(w => w.name)}
                            />
                            <ControlledField title="Évjárat"
                                             value={wine.year.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.year']}
                                             name="year" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="Bruttó ár"
                                             value={wine.price.value}
                                             onChange={this.setPrices}
                                             formControl={formControl['wine.price']}
                                             name="price" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="Nettó ár"
                                             value={wine.netPrice.value}
                                             onChange={this.setPrices}
                                             formControl={formControl['wine.netPrice']}
                                             name="netPrice" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="VIP ár"
                                             value={wine.vipPrice.value}
                                             onChange={this.setPrices}
                                             formControl={formControl['wine.vipPrice']}
                                             name="vipPrice" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="Leírás" value={wine.description.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.description']}
                                             name="description" obj="wine" exampleText=""
                                             className="" multiline rows={8}/>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <FloatingBox sectionTitle="Kép" style={{height: '90%'}}>
                            <ImageUpload handleOpen={() => this.setState({browserOpen: true})}
                                         val={wine.image.value}/>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} item>
                        <FloatingBox sectionTitle="További tulajdonságok">
                            <ControlledField title="Alkohol százalék"
                                             value={wine.alcohol.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.alcohol']}
                                             name="alcohol" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="Borvidék"
                                             value={wine.vineyard.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.vineyard']}
                                             name="vineyard" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="Szőlőfajták (vesszővel elválasztva)"
                                             value={wine.grapeType.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.grapeType']}
                                             name="grapeType" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="Karakter jelzők (vesszővel elválasztva)"
                                             value={wine.character.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.character']}
                                             name="character" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="Technológia"
                                             value={wine.technology.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.technology']}
                                             name="technology" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="Palackozás"
                                             value={wine.packaging.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.packaging']}
                                             name="packaging" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="Cukortartalom"
                                             value={wine.sugarContent.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.sugarContent']}
                                             name="sugarContent" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledField title="Savtartalom"
                                             value={wine.acidity.value}
                                             onChange={changeProperty}
                                             formControl={formControl['wine.acidity']}
                                             name="acidity" obj="wine" exampleText=""
                                             className=""/>
                            <ControlledSelect options={['white', 'rose', 'red']}
                                              title="Szín"
                                              value={wine.color.value}
                                              onChange={changeProperty}
                                              formControl={formControl['wine.color']}
                                              name="color" obj="wine" exampleText=""
                                              className=""
                            />
                            <ControlledSelect options={['sweet', 'semi-sweet', 'semi-dry', 'dry']}
                                              title="Jelleg"
                                              value={wine.dryness.value}
                                              onChange={changeProperty}
                                              formControl={formControl['wine.dryness']}
                                              name="dryness" obj="wine" exampleText=""
                                              className=""
                            />
                            <ControlledSelect title="Címkék" onChange={changeProperty} obj="wine"
                                              value={wine.tags.value || []} name="tags" multiple
                                              options={['NEW', 'TOP']}/>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} md={6} style={{marginBottom: '2em'}}>
                        <FloatingBox sectionTitle="Kapcsolódó borok">
                            <ProductPicker
                                options={wines}
                                onChange={(e, v) => this.handleWinePick(e, v, 'relatedProducts')}/>
                            <Grid container style={{maxHeight: 500, overflowY: 'auto'}}>
                                {wine.relatedProducts.value.map((wineId, idx) => {
                                    const relatedWine = wines.find(w => w.id === wineId);
                                    return <Grid item xs={12} md={6}><ProductItem
                                        product={relatedWine} onDelete={() => {
                                            wine.relatedProducts.value.splice(idx, 1);
                                            changeProperty('wine', 'relatedProducts', wine.relatedProducts.value)
                                        }
                                    }/></Grid>
                                })}
                            </Grid>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FloatingBox sectionTitle="További borok a borászatból">
                            <ProductPicker
                                options={wines}
                                onChange={(e, v) => this.handleWinePick(e, v, 'sameWineryWines')}/>
                            <Grid container style={{maxHeight: 500, overflowY: 'auto'}}>
                                {wine.sameWineryWines.value.map(wineId => {
                                    const wine = wines.find(w => w.id === wineId);
                                    return <Grid item xs={12} md={6}><ProductItem
                                        product={wine}/></Grid>
                                })}
                            </Grid>
                        </FloatingBox>
                    </Grid>
                </Grid>
            </div>

        );
    }
}

ProductEditor.propTypes = {
    wine: PropTypes.any,
    wineIdx: PropTypes.any,
    updateProperty: PropTypes.any,
    updateWine: PropTypes.any
};

export default withFormControl(ProductEditor, {wine: _.cloneDeep(wine)});