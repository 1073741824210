import React from 'react';
import PageableContainer from "../common/table/pageable-container";
import {removeSlide, searchSlides} from "../../service/slider-service";

const sortOptions = [
    {label: 'Kezdet növekvő', field: 'start', order: 'asc'},
    {label: 'Kezdet csökkenő', field: 'start', order: 'desc'},
    {label: 'Vége növekvő', field: 'end', order: 'asc'},
    {label: 'Vége csökkenő', field: 'end', order: 'desc'},
];

function SliderContainer(props) {
    const getSlides = slides => {
        return slides.map(slide => ({
            Kép: {src: slide.image},
            Szlogen: slide.sloganOver,
            Link: slide.link,
        }));
    };

    return (
        <PageableContainer sortOptions={sortOptions} fetchMethod={searchSlides} urlPrefix="slider"
                           pageableDataMethod={getSlides} deleteMethod={removeSlide} urlParam={'id'}/>
    );
}

export default SliderContainer;