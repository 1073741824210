import * as _ from 'lodash'
import {alphabetical, alphaNumerical, numerical, percentage} from "./regex";

export const post = {
    id: {value: '', required: true, regex: ''},
    summary: {value: '', required: true, regex: ''},
    html: {value: '', required: true, regex: ''},
    name: {value: '', required: true, regex: _.cloneDeep(alphabetical)},
    publishDate: {value: '', required: true, regex: ''},
    lastModificationDate: {value: '', required: true, regex: _.cloneDeep(percentage)},
    coverImage: {value: '', required: true, regex: _.cloneDeep(percentage)},
    publisher: {value: '', required: true, regex: _.cloneDeep(percentage)},
};