import React from 'react';
import ControlledInput from "../base/controlled-input";
import SimpleForm from "./simple-form";

const EmailForm = ({control, disabled, onChange, errors, defaultValue = ""}) => {
    return (
        <ControlledInput
            control={control}
            errors={errors}
            name="email"
            onChange={onChange}
            disabled={disabled}
            title="Email"
            rules={{required: "Ez mező kötelezően kitöltendő",
                pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Érvénytelen email cím formátum"
                }
            }}
            defaultValue={defaultValue}
            type="email"
        />
    );
};

export default EmailForm;