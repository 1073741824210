import React from 'react';
import ListItemText from "@material-ui/core/ListItemText";
import {withRouter} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";

const NavLink = ({item, history}) => (
    <ListItem button key={item.label} onClick={() => history.push(item.to)}>
            <ListItemText primary={item.label} primaryTypographyProps={{
                variant: 'h6'
            }}/>
    </ListItem>
);

export default withRouter(NavLink);