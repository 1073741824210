import React from 'react';
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import ProductPicker from "../wine-picker/product-picker";
import './wine-category.css'
import Button from "@material-ui/core/Button";
import ProductItem from "../product/product-item";

const WineCategory = ({isNew, wines, wineCategory, handleDeleteItem, searchInclusion, handlePick, idx, handleAdd, handleNameChange, handleDelete}) => {
    if (isNew) {
        return <Paper className="wineCategoryContainer">
            <div style={{height: '90%'}}>
                <TextField fullWidth onChange={e => handleNameChange(e.target.value, idx, isNew)} value={wineCategory.category} label="Új kategória neve"/>
                <ProductPicker onChange={(e, wine) => handlePick(wine, idx, isNew)}
                               exclusions={{id: wineCategory.wines.map(w => w.id)}}
                               options={wines}/>
                <div style={{margin: '1em 0', height: 250, overflowY: 'auto'}}>
                    {wineCategory.wines.map(wine => <ProductItem product={wine}/>)}
                </div>
            </div>
            <Button disabled={!wineCategory.wines.length} onClick={handleAdd}>Hozzáadás</Button>
        </Paper>
    }

    return <Paper className="wineCategoryContainer">
        <div  style={{height: '90%'}}>
        <TextField fullWidth onChange={e => handleNameChange(e.target.value, idx, isNew)} value={wineCategory.category}
                   label="Kategória neve"/>
            <ProductPicker onChange={(e, wine) => handlePick(wine, idx, isNew)}
                           exclusions={{id: wineCategory.wines.map(w => w.id)}}
                           options={wines}/>
            <div style={{margin: '1em 0', height: 250, overflowY: 'auto'}}>
                {wineCategory.wines.map(wine => <ProductItem product={wine} onDelete={() => handleDeleteItem(wine.id)}/>)}
            </div>
            <Button onClick={() => handleDelete(idx)}>Eltávolítás</Button>
        </div>
    </Paper>
};

export default WineCategory;