import * as React from "react";
import {isFieldDataValid, validate} from "./form-validation";
import * as _ from 'lodash';
import {changeType} from "../model/contact";

export const withFormControl = (WrappedComponent, initialData) => {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                formControl: {},
                init: {},
            }
        }

        componentDidMount() {
            if (initialData) this.setState({data: initialData, init: _.cloneDeep(initialData)})
        }

        resolve = (dataField, data) => {
            dataField = dataField.split('.');
            while (dataField.length) {
                if (typeof data !== 'object') return undefined;
                data = data[dataField.shift()];
            }
            return data;
        };

        addError = (dataField, field, e) => {
            const {formControl} = this.state;
            formControl[dataField][field].error = true;
            formControl[dataField][field].msg = e;
            this.setState({formControl})
        };

        setData = (data) => {
            this.setState({data, init: _.cloneDeep(data)})
        };

        updateData = (updateData, dataField) => {
            let {data} = this.state;
            if (!data) {
                data = initialData;
            }
            Object.keys(updateData).forEach(k => {
                if (data[dataField][k])
                    data[dataField][k].value = updateData[k]
            });
            this.setState({data, init: _.cloneDeep(data)})
        };

        clearData = dataField => {
            const {data} = this.state;
            const resolved = this.resolve(dataField, data);
            Object.keys(resolved).forEach(k => resolved[k].value = '');
            this.setState({data})
        };

        isValid = (obj, dataField, field) => {
            const {formControl} = this.state;
            isFieldDataValid(obj, dataField, field, formControl);
            this.setState({formControl});
        };

        changeProperty = (dataField, field, value) => {
            const data = this.state.data;
            const resolved = this.resolve(dataField, data);
            resolved[field].value = value;
            this.setState({data});
            this.isValid(resolved, dataField, field)
        };

        changeContactType = (type, dataField) => {
            const {data, formControl} = this.state;
            changeType(data, dataField, type, formControl);
            this.setState({data, formControl});
        };

        doValidation = dataField => {
            const {data, formControl} = this.state;
            let valid;

            if (dataField) {
                valid = this.validateSingle(data, dataField, formControl)
            } else {
                valid = this.validatesAll(data, formControl)
            }

            this.setState({formControl});
            return valid;
        };

        validatesAll = (data, formControl) => {
            let valid = true;
            Object.keys(data).forEach(dataField => {
                if (valid) {
                    valid = validate(data[dataField], dataField, formControl);
                } else {
                    validate(data[dataField], dataField, formControl);
                }
            });

            return valid;
        };

        validateSingle = (data, dataField, formControl) => {
            const toValidate = this.resolve(dataField, data);
            return validate(toValidate, dataField, formControl);
        };

        resetValidation = () => {
            this.setState({formControl: {}})
        };

        resetData = () => {
            const {init} = this.state;
            this.setData(init);
            this.resetValidation()
        };

        render() {
            const {data, formControl, init} = this.state;

            if (!data && initialData) {
                return null;
            }

            const modified = !_.isEqual(data, init);

            return <WrappedComponent formControl={formControl} changeContactType={this.changeContactType} data={data}
                                     doValidation={this.doValidation} changeProperty={this.changeProperty}
                                     modified={modified} resetData={this.resetData}{...this.props}
                                     updateData={this.updateData} resetValidation={this.resetValidation}
                                     addError={this.addError} setData={this.setData} clearData={this.clearData}/>
        }
    }
};