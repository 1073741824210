import React from 'react';
import {TextField} from "@material-ui/core";
import './form-elements.css'

function ControlledField({title, formControl, disabled, value, onChange, exampleText, name, obj, className, ...props}) {
    return (
        <div className={(formControl && !formControl.error) ? "fieldContainer valid" : "fieldContainer"}>
            <TextField
                label={title}
                variant="outlined"
                fullWidth
                disabled={disabled}
                error={formControl && formControl.error}
                helperText={formControl && formControl.msg}
                value={value || ''}
                onChange={e => onChange(obj, e.target.name, e.target.value)}
                name={name}
                className={`${className} controlledField`}
                {...props}
            />
            {exampleText && <div style={{width: '30%', fontSize: '.85em'}}>{exampleText}</div>}
        </div>
    );
}

export default ControlledField;