import React, {Component} from 'react';
import {TextField} from "@material-ui/core";
import './login.css'
import Button from "@material-ui/core/Button";
import {login} from "../../service/auth-service";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import icon from '../../resource/ikon.png'
import FormControl from "@material-ui/core/FormControl";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            error: "",
        }
    }

    login = () => {
        const {email, password} = this.state;
        login({email, password})
            .then(() => this.setState({error: ''}))
            .catch(err => this.setState({error: err.response ? err.response.data : 'Sikertelen bejelentkezés'}))
    };

    setEmail = event => {
        this.setState({email: event.target.value});
    };

    setPassword = event => {
        this.setState({password: event.target.value});
    };

    render() {
        const {email, password, error} = this.state;

        return (
            <div className="loginContainer">
                <div style={{position: 'absolute', zIndex: -1}}>
                    <img style={{width: '100%'}} src={icon}/>
                </div>
                <Paper className="formContainer">
                    <Typography variant="h4" style={{margin: '.3em 1em 1em 1em'}}>
                        Bejelentkezés
                    </Typography>
                    <form>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            height: '80%'
                        }}>
                            <div>
                                <div>
                                    <TextField onChange={this.setEmail} value={email} fullWidth label="Email cím"
                                               type="email"/>
                                </div>
                                <div>
                                    <TextField onChange={this.setPassword} value={password} fullWidth label="Jelszó"
                                               type="password"/>
                                </div>
                            </div>
                            {error && (<div style={{textAlign: 'center', marginBottom: '1em', color: 'red'}}>
                                <Typography variant="h6">{error}</Typography>
                            </div>)}
                            <div style={{marginTop: '5em'}}>
                                <Button variant="contained" color="primary" type="submit" style={{
                                    transition: '.5s',
                                    width: '10em'
                                }} onClick={e => {
                                    e.preventDefault();
                                    this.login()
                                }}>BEJELENTKEZÉS</Button>
                            </div>
                        </div>
                    </form>
                </Paper>
            </div>
        );
    }
}

export default Login;