import React from 'react';
import PageableContainer from "../common/table/pageable-container";
import {removeThisUser, searchUsers} from "../../service/user-service";


const sortOptions = [
    {label: 'Név A-Z', field: 'name', order: 'asc'},
    {label: 'Név Z-A', field: 'name', order: 'desc'},
];

const UserContainer = () => {
    const getUserData = users => {
        return users.map(user => ({
            Név: user.name,
            Email: user.email,
            Rendelések: user.orders.length,
        }));
    };

    return <PageableContainer sortOptions={sortOptions} fetchMethod={searchUsers} urlPrefix="felhasznalo" withoutCreation
                              pageableDataMethod={getUserData} deleteMethod={removeThisUser} urlParam={'id'}/>

};

export default UserContainer;