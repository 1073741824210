import React from 'react';
import Paper from "@material-ui/core/Paper";

function FloatingBox({sectionTitle, children, ...props}) {
    return (
        <Paper className="floatingBoxContainer" {...props}>
            <div className="floatingBoxInnerPadding">
                {sectionTitle && <div className="floatingBoxSectionTitle">
                    {sectionTitle}
                </div>}
                {children}
            </div>
        </Paper>
    );
}

export default FloatingBox;