import React from 'react';
import {deleteWine, searchWines} from "../../service/wine-service";
import {withRouter} from "react-router-dom";
import PageableContainer from "../common/table/pageable-container";

const sortOptions = [
    {label: 'Név A-Z', field: 'name', order: 'asc'},
    {label: 'Név Z-A', field: 'name', order: 'desc'},
    {label: 'Borászat A-Z', field: 'winery', order: 'asc'},
    {label: 'Borászat Z-A', field: 'winery', order: 'desc'}
];

class ProductContainer extends React.Component {

    getProductsData = wines => {
        return wines.map(wine => ({
            Kép: {src: wine.image},
            Aktív: wine.active,
            Név: wine.name,
            Borászat: wine.winery,
            Évjárat: wine.year
        }));
    };

    render() {
        return (<PageableContainer sortOptions={sortOptions} fetchMethod={searchWines} urlPrefix="bor" urlParam={'urlPart'}
                                   regexFilter={{key: 'name', label: 'Névre szűrés'}}
                                   pageableDataMethod={this.getProductsData} deleteMethod={deleteWine}/>)
    }
}

export default withRouter(ProductContainer);