import React from 'react';
import PageableContainer from "../common/table/pageable-container";
import {removeTranslation, searchTranslation} from "../../service/translation-service";

const sortOptions = [
    {label: 'Kezdet növekvő', field: 'start', order: 'asc'},
    {label: 'Kezdet csökkenő', field: 'start', order: 'desc'},
    {label: 'Vége növekvő', field: 'end', order: 'asc'},
    {label: 'Vége csökkenő', field: 'end', order: 'desc'},
];

function TranslationsContainer(props) {
    const getTranslations = translations => {
        return translations.map(translation => ({
            Kulcs: translation.key,
            Magyar: translation.hungarian,
            Angol: translation.english,
        }));
    };

    return <PageableContainer sortOptions={sortOptions} fetchMethod={searchTranslation} urlPrefix="forditas" regexFilter={{key: 'key', label: 'Kulcsra szűrés'}}
                              pageableDataMethod={getTranslations} deleteMethod={removeTranslation} urlParam={'id'}/>
}

export default TranslationsContainer;