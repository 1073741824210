const initialState = {
    authenticated: false,
    user: null,
    locale: 'hungarian',
    loading: false,
    port: 1366,
    translations: [],
    feedbackBarOpen: {
        open: false,
        message: '',
        color: ''
    },
    confirmationState: {
        open: false,
        callback: null
    }
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case "AUTHENTICATE":
            return {...state, authenticated: true, user: action.payload};
        case "LOGOUT":
            return {...state, authenticated: false, user: null};
        case "LANG":
            return {...state, lang: action.payload};
        case "LOADING":
            return {...state, loading: action.payload};
        case "PORT":
            return {...state, port: action.payload};
        case "FEEDBACK_BAR_OPEN":
            return {...state, feedbackBarOpen: action.payload};
        case "CONFIRMATION":
            return {...state, confirmationState: action.payload};
        case "TRANSLATIONS":
            return {...state, translations: action.payload};
        default:
            return state;
    }
}

export default rootReducer;