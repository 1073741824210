import {store} from './store';
import {setFeedbackBar} from "./action";

export const setError = (message) => {
    store.dispatch(setFeedbackBar({message, color: '#af3310', open: true}))
};

export const setSuccess = (message) => {
    store.dispatch(setFeedbackBar({message, color: '#71de44', open: true}))
};

export const setWarning = (message) => {
    store.dispatch(setFeedbackBar({message, color: '#ff9c0b', open: true}))
};

export const closeFeedback = () => {
    store.dispatch(setFeedbackBar({message: '', color: '', open: false}))
};