import React, {Component} from 'react';
import './post-editor.css'
import * as _ from "lodash";
import FormActionBar from "../common/form/form-action-bar";
import FloatingBox from "../common/form/floating-box";
import ControlledField from "../common/form/controlled-field";
import {withFormControl} from "../../hoc/form-controller";
import {post} from "../../model/post";
import {createPost, getPostByUrl, updatePost} from "../../service/post-service";
import {setError, setSuccess} from "../../redux/redux-feedback-service";
import CkRichEditor from "../common/text-editor/ck-rich-editor";
import Grid from "@material-ui/core/Grid";
import ImageUpload from "../common/form/image-upload";
import ImageBrowserDialog from "../common/file-browser/image-browser-dialog";

class PostEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: {},
            selectImage: false,
            mode: '',
        }
    };

    componentDidMount() {
        const {match, updateData, setData} = this.props;

        if (match.params && match.params.urlPart !== 'uj') {
            getPostByUrl(match.params.urlPart).then(res => {
                this.setState({
                    mode: 'edit',
                });
                updateData(res, 'post')
            });
        } else {
            setData({post: _.cloneDeep(post)});
            this.setState({mode: 'create'})
        }
    }

    updatePost = () => {
        this.doBlogRequest(updatePost)
    };

    createPost = () => {
        this.doBlogRequest(createPost)
    };

    doBlogRequest = method => {
        const {data} = this.props;
        const post = {};

        Object.keys(data.post).forEach(k => {
            post[k] = data.post[k].value
        });

        method(post)
            .then(() => {
                setSuccess('Sikeres mentés');
                window.history.back();
            })
            .catch(() => setError('Sikertelen mentés'))
    };

    onChangeHtml = data => {
        const {changeProperty} = this.props;
        changeProperty('post', 'html', data)
    };

    render() {
        const {mode, browserOpen} = this.state;
        const {data, formControl, modified, changeProperty, resetData} = this.props;
        const {post} = data;

        if (!post) {
            return null;
        }

        return (
            <div style={{width: '100%'}}>
                <ImageBrowserDialog handleOpen={(path) => {
                    changeProperty('post', 'coverImage', path);
                    this.setState({browserOpen: false})
                }} open={browserOpen} onClose={() => this.setState({browserOpen: false})}/>
                <FormActionBar reset={resetData} modified={modified}
                               update={mode === 'edit' ? this.updatePost : this.createPost}/>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <FloatingBox sectionTitle="Fő tulajdonságok" style={{height: '85%'}}>
                            <ControlledField title="Név"
                                             value={post.name.value}
                                             onChange={changeProperty}
                                             formControl={formControl['post.name']}
                                             name="name" obj="post" exampleText=""
                                             className=""/>
                            <ControlledField title="Összefoglalás"
                                             value={post.summary.value} multiline rows={5}
                                             onChange={changeProperty}
                                             formControl={formControl['post.summary']}
                                             name="summary" obj="post" exampleText=""
                                             className=""/>
                        </FloatingBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FloatingBox sectionTitle="Kép" style={{height: '85%'}}>
                            <ImageUpload title="Borítókép"
                                         handleOpen={() => this.setState({browserOpen: true})}
                                         val={post.coverImage.value}/>
                        </FloatingBox>
                    </Grid>
                    <Grid item xs={12}>
                        <FloatingBox sectionTitle="Cikk szöveg">
                            <div style={{maxHeight: 800, overflowY: 'auto'}}>
                                <CkRichEditor data={post.html.value} handleChange={this.onChangeHtml}/>
                            </div>
                        </FloatingBox>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withFormControl(PostEditor, {post: _.cloneDeep(post)});