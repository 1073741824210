import React from 'react';
import {deleteWinery, searchWineries} from "../../service/winery-service";
import PageableContainer from "../common/table/pageable-container";

const sortOptions = [
    {label: 'Borászat A-Z', field: 'name', order: 'asc'},
    {label: 'Borászat Z-A', field: 'name', order: 'desc'},
];

class WineryContainer extends React.Component {


    getWineryData = wineries => {
        return wineries.map(winery => ({
            Kép: {src: winery.logoImage},
            Aktív: winery.active,
            Borászat: winery.name,
            Borász: winery.enologist,
            'Borok száma': winery.wines.length,
        }));
    };

    render() {
        return <PageableContainer sortOptions={sortOptions} fetchMethod={searchWineries} urlPrefix="boraszat" regexFilter={{key: 'name', label: 'Névre szűrés'}}
                                  pageableDataMethod={this.getWineryData} deleteMethod={deleteWinery} urlParam={'urlPart'}/>
    }
}

export default WineryContainer;