import React, {Component} from 'react';
import PageableContainer from "../common/table/pageable-container";
import {deleteOrder, searchOrders} from "../../service/order-service";
import moment from "moment";
import {formatPrice} from "../../service/formatter";
import {getTranslationByKey} from "../../redux/redux-translation-service";


const sortOptions = [
    {label: 'Legutóbbiak', field: 'created', order: 'desc'},
    {label: 'Legrégebbiek', field: 'created', order: 'asc'},
    {label: 'Összeg növekvő', field: 'price', order: 'asc'},
    {label: 'Összeg csökkenő', field: 'price', order: 'desc'},
];

class OrderContainer extends Component {

    getWineryData = orders => {
        return orders.map(order => ({
            Státusz: getTranslationByKey(order.statuses[order.statuses.length-1].status),
            Név: order.name,
            Sorszám: order.serial,
            Azonosító: order.key,
            Összeg: formatPrice(order.price + order.cashOnDeliveryCost + order.deliveryCost),
            Rögzítés: moment(order.created).format("YYYY. MM. DD. HH:mm"),
        }));
    };

    render() {
        return <PageableContainer sortOptions={sortOptions} fetchMethod={searchOrders} urlPrefix="rendeles" urlParam={'id'}
                                  pageableDataMethod={this.getWineryData} deleteMethod={deleteOrder} regexFilter={{key: 'key', label: 'Azonosítóra szűrés'}}
                                  withoutCreation regexKey="key"/>
    }
}

export default OrderContainer;