import {email} from "./email";
import {contact} from "./contact";

const rePassword = {
    value: '',
    required: true,
};

const oldPassword = {
    value: '',
    required: true,
};

const password = {
    value: '',
    required: true,
    regex: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        errorType: 'password'
    }
};

export const user = {
    contact,
    email: {email}
};

export const passwordChange = {
    oldPassword,
    password,
    rePassword
};

export const newPassword = {
    password,
    rePassword
};
