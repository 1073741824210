import {get, post, put, remove} from "./auth-service";
import {resourceServer} from "../constants";

export const getAllPacks = () => {
    return get(`${resourceServer}/packs`)
        .then(res => res.data)
};

export const getPackByUrl = url => {
    return get(`${resourceServer}/packs:url/${url}`)
        .then(res => res.data)
};

export const createPack = (postObj) => {
    return post(`${resourceServer}/packs`, JSON.stringify(postObj))
};

export const updatePack = post => {
    return put(`${resourceServer}/packs`, JSON.stringify(post))
};

export const deletePack = id => {
    return remove(`${resourceServer}/packs/${id}`)
};

export const searchPacks = (searchMap) => {
    return post(`${resourceServer}/packs:search?all=true`, JSON.stringify(searchMap))
};