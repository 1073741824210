import {get, post, put, remove} from "./auth-service";
import {resourceServer} from "../constants";

export const getAllWines = () => {
    return get(`${resourceServer}/wines`)
        .then(res => res.data)
};

export const getWineByUrlPart = url => {
    return get(`${resourceServer}/wines:url/${url}`)
        .then(res => res.data)
};

export const getProductsByIds = id => {
    return post(`${resourceServer}/products:fetch`, JSON.stringify(id))
        .then(res => res.data)
};

export const searchWines = (searchMap) => {
    return post(`${resourceServer}/wines:search?all=true`, JSON.stringify(searchMap))
};

export const searchProducts = (searchMap) => {
    return post(`${resourceServer}/products:search?all=true`, JSON.stringify(searchMap))
};

export const createWine = (wine) => {
    return post(`${resourceServer}/wines`, JSON.stringify(wine))
};

export const updateWine = (wine) => {
    return put(`${resourceServer}/wines`, JSON.stringify(wine))
};

export const deleteWine = id => {
    return remove(`${resourceServer}/wines/${id}`)
};
