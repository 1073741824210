import React from 'react';
import './App.css';
import {Provider} from "react-redux";
import {store} from './redux/store'
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import SecurityGate from "./component/auth/security-gate";

class App extends React.Component {

    theme = createMuiTheme({
        overrides: {},
        props: {
            MuiPaper: {
                elevation: 15
            }
        },
        palette: {
            primary: {
                main: '#f38c37',
            },
            secondary: {
                main: '#777'
            }
        }
    });


    render() {
        return (
            <Provider store={store}>
                <ThemeProvider theme={this.theme}>
                    <SecurityGate/>
                </ThemeProvider>
            </Provider>
        );
    }
}

export default App;
