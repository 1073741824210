import React, {Component} from 'react';
import Nav from "../nav/nav";
import Login from "./login";
import {setLoading} from "../../redux/action";
import {getAccessToken} from "../../service/auth-service";
import {connect} from "react-redux";

class SecurityGate extends Component {

    componentDidMount() {
        const { doSetLoading } = this.props;

        doSetLoading(true);
        getAccessToken().finally(() => doSetLoading(false));
    }

    render() {
        const { authenticated, loading } = this.props;

        if (loading) {
            return null
        }

        return authenticated ? <Nav/> : <Login/>
    }
}


const mapDispatchToProps = dispatch => {
    return {
        doSetLoading: loading => dispatch(setLoading(loading)),
    }
};

const mapStateToProps = state => {
    return {authenticated: state.authenticated, loading: state.loading}
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityGate);