import React, {useEffect, useState} from 'react';
import {createPack, getPackByUrl, updatePack} from "../../service/pack-service";
import ImageBrowserDialog from "../common/file-browser/image-browser-dialog";
import {setError, setSuccess} from "../../redux/redux-feedback-service";
import Backdrop from "@material-ui/core/Backdrop";
import {useForm} from "react-hook-form";
import FormActionBar from "../common/form/form-action-bar";
import Grid from "@material-ui/core/Grid";
import FloatingBox from "../common/form/floating-box";
import ImageUpload from "../common/form/image-upload";
import ControlledInput from "../form/base/controlled-input";
import ControlledCheckbox from "../form/base/controlled-checkbox";
import ProductPicker from "../common/wine-picker/product-picker";
import * as _ from 'lodash'
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ProductItem from "../common/product/product-item";

const PackDetail = ({match}) => {
    const [pack, setPack] = useState(null);
    const [initWines, setInitWines] = useState([]);
    const [initRelatedProducts, setInitRelatedProducts] = useState([]);
    const [netPrice, setNetPrice] = useState(0);
    const [price, setPrice] = useState(0);
    const [image, setImage] = useState('');
    const [mode, setMode] = useState('');
    const [browserOpen, setBrowserOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const {control, errors, setValue, getValues, handleSubmit, reset, formState} = useForm();

    useEffect(() => {
        if (match.params && match.params.urlPart !== 'uj') {
            getPackByUrl(match.params.urlPart).then(res => {
                const pack = res;
                pack.image = !pack.image ? '' : pack.image;
                setMode('edit');
                setInitWines([...pack.wines] || []);
                setInitRelatedProducts([...pack.relatedProducts] || []);
                setPrice(pack.price || 0);
                setImage(pack.image || '');
                setNetPrice(pack.netPrice || 0);
                setPack(pack);
            }).finally(() => setLoading(false))
        } else {
            setLoading(false);
            setPack({wines: [], tags: [], relatedProducts: []});
        }
    }, []);

    const doUpdatePack = () => {
        doPackRequest(updatePack)
    };

    const doCreatePack = () => {
        doPackRequest(createPack)
    };

    const doPackRequest = method => {
        pack.price = price;
        pack.netPrice = netPrice;
        pack.name = getValues('name');
        pack.description = getValues('description');
        pack.vipPrice = getValues('vipPrice');
        pack.active = getValues('active');
        pack.type = "PACK";

        pack.relatedProducts.forEach(wine => {
            if (wine.type === 'PACK') {
                wine.wines = wine.wines.map(w => w.id);
                wine.relatedProducts = wine.relatedProducts.map(w => w.id);
            }
        });

        method(pack)
            .then(res => {
                setSuccess('Sikeres mentés');
                window.history.back();
            })
            .catch(err => setError('Sikertelen mentés'))
    };

    const setPrices = (name, value) => {
        if (name === 'price') {
            setValue("netPrice", Math.round((value / 1.27)));
            setNetPrice(Math.round((value / 1.27)))
        } else if (name === 'netPrice') {
            setValue("price", Math.round((value * 1.27)));
            setPrice(Math.round((value * 1.27)))
        }
    };

    if (!pack || loading) {
        return <div>
            <Backdrop open/>
        </div>
    }

    return (
        <div>
            <ImageBrowserDialog handleOpen={path => {
                pack.image = path;
                setPack({...pack});
                setBrowserOpen(false)
            }} open={browserOpen} onClose={() => setBrowserOpen(false)}/>
            <FormActionBar reset={reset}
                           modified={formState.isDirty || !_.isEqual(initWines, pack.wines) || !_.isEqual(initRelatedProducts, pack.relatedProducts) || image !== pack.image || netPrice !== pack.netPrice || price !== pack.price}
                           update={mode === 'edit' ? handleSubmit(doUpdatePack) : handleSubmit(doCreatePack)}/>
            <Grid container>
                <Grid xs={12} md={6} item>
                    <FloatingBox sectionTitle="Fő tulajdonságok" style={{height: '90%', marginBottom: '2em'}}>
                        <ControlledCheckbox title="Aktív" defaultValue={pack.active} control={control}
                                            value={pack.active} color="primary" name="active"
                        />
                        <ControlledInput title="Név" fullWidth
                                         defaultValue={pack.name}
                                         onChange={e => setValue('name', e.target.value, {shouldDirty: true})}
                                         name="name" errors={errors} control={control}/>
                        <div style={{margin: 10}}/>
                        <ControlledInput title="Bruttó ár" errors={errors} control={control}
                                         defaultValue={price} fullWidth onChange={e => {
                            setValue("price", e.target.value);
                            setPrice(e.target.value);
                            setPrices("price", e.target.value)
                        }} value={price} name="price"/>
                        <div style={{margin: 10}}/>
                        <ControlledInput title="Nettó ár" errors={errors} control={control}
                                         defaultValue={netPrice} fullWidth onChange={e => {
                            setNetPrice(e.target.value);
                            setValue("netPrice", e.target.value);
                            setPrices("netPrice", e.target.value)
                        }} value={netPrice} name="netPrice"/>
                        <div style={{margin: 10}}/>
                        <ControlledInput title="VIP ár" errors={errors} control={control}
                                         defaultValue={pack.vipPrice} fullWidth
                                         onChange={e => setValue('vipPrice', e.target.value, {shouldDirty: true})}
                                         name="vipPrice"/>
                        <div style={{margin: 10}}/>
                        <ControlledInput control={control} errors={errors} defaultValue={pack.description}
                                         title="Leírás" fullWidth
                                         onChange={e => setValue('description', e.target.value, {shouldDirty: true})}
                                         name="description" multiline rows={8}/>
                        <div style={{marginTop: 10}}>
                            <Select fullWidth
                                    renderValue={(selected) => selected.join(', ')} variant="outlined" title="Címkék"
                                    onChange={e => {
                                        pack.tags = e.target.value;
                                        setPack({...pack})
                                    }}
                                    value={pack.tags} name="tags" multiple>
                                {['NEW', 'TOP'].map(item => <MenuItem value={item} key={item}>
                                    <ListItemText primary={item}/>
                                </MenuItem>)}
                            </Select>
                        </div>
                    </FloatingBox>
                </Grid>
                <Grid xs={12} md={6} item>
                    <FloatingBox sectionTitle="Kép" style={{height: '90%'}}>
                        <ImageUpload handleOpen={() => setBrowserOpen(true)}
                                     val={pack.image}/>
                    </FloatingBox>
                </Grid>
                <Grid xs={12} md={6} item>
                    <FloatingBox sectionTitle="Borok">
                        <div style={{margin: '0 auto'}}>
                            <div style={{maxWidth: 500}}>
                                <ProductPicker exclusions={{id: pack.wines}}
                                               onChange={(e, wine) => {
                                                if (!wine) {
                                                    return
                                                }
                                                pack.wines.push(wine);
                                                setPack({...pack});
                                            }}/>
                            </div>
                            <Grid container style={{maxHeight: 500, overflowY: 'auto'}}>
                                {pack.wines.map((wine, idx) =>
                                    <Grid item xs={12} sm={6} md={4} key={`${idx}-pack-wine-item`}>
                                        <ProductItem product={wine} onDelete={() => {
                                            pack.wines.splice(idx, 1);
                                            setPack({...pack})
                                        }}/></Grid>)}
                            </Grid>
                        </div>
                    </FloatingBox>
                </Grid>
                <Grid xs={12} md={6} style={{marginBottom: '2em'}}>
                    <FloatingBox sectionTitle="Kapcsolódó borok">
                        <ProductPicker
                            exclusions={{id: pack.relatedProducts}}
                            onChange={(e, wine) => {
                                if (!wine) {
                                    return
                                }

                                pack.relatedProducts.push(wine);
                                setPack({...pack});
                            }}/>
                        <Grid container style={{maxHeight: 500, overflowY: 'auto'}}>
                            {pack.relatedProducts.map((wine, idx) => {
                                return <Grid item xs={12} md={6}><ProductItem
                                    product={wine} onDelete={() => {
                                    pack.relatedProducts.splice(idx, 1);
                                    setPack({...pack})
                                }}/></Grid>
                            })}
                        </Grid>
                    </FloatingBox>
                </Grid>
            </Grid>
        </div>
    );
};

export default PackDetail;