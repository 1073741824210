import {get, post, remove} from "./auth-service";
import {resourceServer} from "../constants";

export const getAllImages = path => {
    return get(`${resourceServer}/images?path=${path}`)
};

export const getImage = name => {
    return get(`${resourceServer}/images?path=${name}`)
};

export const saveImage = (file, path, type) => {
    return post(`${resourceServer}/images?path=${path}&type=${type}`, file)
};

export const moveFiles = (target, paths) => {
    return post(`${resourceServer}/images:move?path=${target}`, JSON.stringify(paths))
};

export const mkdir = path => {
    return get(`${resourceServer}/images:mkdir?path=${path}`)
};

export const removeImage = paths => {
    return remove(`${resourceServer}/images:remove?paths=${paths.join(",")}`)
};

export const getImageTree = () => {
    return get(`${resourceServer}/images:tree`)
};