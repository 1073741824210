import React from 'react';
import SimpleForm from "./single/simple-form";
import TelephoneForm from "./single/telephone-form";
import EmailForm from "./single/email-form";
import Grid from "@material-ui/core/Grid";
import ControlledCheckbox from "./base/controlled-checkbox";

const defaults = {
    name: '',
    phoneNumber: '',
    email: '',
};

const ContactForm = ({control, setValue, errors, defaultValues = defaults, disabled}) => {
    return (
        <Grid container spacing={2} style={{margin: '2em 0'}}>
            <Grid item xs={12} sm={4}>
                <SimpleForm title="Név"
                            onChange={e => setValue('name', e.target.value)}
                            errors={errors}
                            name="name"
                            required
                            disabled={disabled}
                            defaultValue={defaultValues['name']}
                            control={control}/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <EmailForm control={control} errors={errors}
                           disabled={disabled}
                           onChange={e => setValue('email', e.target.value)} defaultValue={defaultValues['email']}/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TelephoneForm control={control} errors={errors} onChange={e => setValue('phoneNumber', e.target.value)}
                               disabled={disabled}
                               defaultValue={defaultValues['phoneNumber']}/>
            </Grid>
        </Grid>
    );
};

export default ContactForm;
