import React from 'react';
import FloatingBox from "../common/form/floating-box";
import {withFormControl} from "../../hoc/form-controller";
import {user} from "../../model/user";
import {useForm} from "react-hook-form";
import ContactForm from "../form/contact-form";

const UserAccountContainer = ({name, email, phoneNumber}) => {
    const {control, errors, setValue, reset, getValues} = useForm();
    return (<ContactForm defaultValues={{name, email, phoneNumber}} control={control} errors={errors} disabled
                         setValue={setValue}/>
    );
};

export default withFormControl(UserAccountContainer, user);