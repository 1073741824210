import {errorMessages} from "../model/error-messages";

export const isFieldDataValid = (data, dataField, field, formControl) => {
    isFieldValid(data, dataField, field, formControl)
};

const isFieldValid = (data, dataField, field, formControl, allowError) => {
    const dataToValidate = data[field];
    let valid = true;
    let message = '';
    if (dataToValidate.required && !dataToValidate.value) {
        valid = false;
        message = errorMessages.empty
    }

    if (dataToValidate.value && dataToValidate.regex && !dataToValidate.regex.value.test(dataToValidate.value)) {
        valid = false;
        message = errorMessages[dataToValidate.regex.errorType]
    }

    if (field === 'rePassword' && data.password.value !== data.rePassword.value) {
        valid = false;
        message = errorMessages[field]
    }

    if (!formControl[`${dataField}.${field}`]) {
        formControl[`${dataField}.${field}`] = {error: false, msg: ''}
    }

    if (valid) {
        formControl[`${dataField}.${field}`].error = false;
        formControl[`${dataField}.${field}`].msg = '';
        return true;
    } else if (!valid && allowError) {
        formControl[`${dataField}.${field}`].error = true;
        formControl[`${dataField}.${field}`].msg = message;
        return false;
    }
};

export const validate = (data, dataField, formControl) => {
    let valid = true;
    Object.keys(data).forEach(field => {
        if(valid) {
            valid = isFieldValid(data, dataField, field, formControl, true)
        } else {
            isFieldValid(data, dataField, field, formControl, true)
        }
    });
    return valid;
};