import React from 'react';
import './winery-editor.css'
import {createWinery, getWineryByUrlWithWines, updateWinery} from "../../service/winery-service";
import * as _ from 'lodash'
import FloatingBox from "../common/form/floating-box";
import ControlledField from "../common/form/controlled-field";
import FormActionBar from "../common/form/form-action-bar";
import {withFormControl} from "../../hoc/form-controller";
import {winery} from "../../model/winery";
import {setError, setSuccess} from "../../redux/redux-feedback-service";
import Grid from "@material-ui/core/Grid";
import ProductPicker from "../common/wine-picker/product-picker";
import WineCategory from "../common/wine-category/wine-category";
import ImageUpload from "../common/form/image-upload";
import ImageBrowserDialog from "../common/file-browser/image-browser-dialog";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import {setConfirmationState} from "../../redux/redux-confirmation-service";
import ProductItem from "../common/product/product-item";

class WineryEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modified: false,
            handleImageOpen: null,
            selectImage: '',
            images: [],
            newWineCategory: {category: '', wines: []},
            wineCategories: [],
            browserOpen: false,
        };
    }

    componentDidMount() {
        const {match, updateData, setData} = this.props;

        if (match.params && match.params.urlPart !== 'uj') {
            getWineryByUrlWithWines(match.params.urlPart).then(res => {
                updateData(res, 'winery');
                this.setState({
                    mode: 'edit',
                    wineCategories: res.categories ? res.categories.map(cat => ({
                        ...cat,
                        wines: res.wines.filter(w => cat.wines.includes(w.id))
                    })) : []
                })
            });
        } else {
            setData({winery: _.cloneDeep(winery)});
            this.setState({mode: 'create'})
        }
    }

    updateWinery = () => {
        this.doWineRequest(updateWinery)
    };

    createWinery = () => {
        this.doWineRequest(createWinery)
    };

    doWineRequest = method => {
        const {wineCategories} = this.state;
        const {data} = this.props;
        const winery = {};

        if (data.winery.displayNameIsWinery.value) {
            winery.displayName = data.winery.name.value;
        } else {
            winery.displayName = data.winery.enologist.value;
        }

        Object.keys(data.winery).forEach(k => winery[k] = data.winery[k].value);
        winery.wines = winery.wines.map(wine => ({id: wine.id, order: wine.order}));
        wineCategories.forEach(cat => cat.wines.forEach(wine => wine.id));
        winery.categories = wineCategories.map(cat => ({...cat, wines: cat.wines.map(wine => wine.id)}));


        method(winery)
            .then(res => {
                setSuccess('Sikeres mentés');
                window.history.back();
            })
            .catch(err => setError('Sikertelen mentés'))
    };

    handleNewWine = (e, wine) => {
        if (!wine) {
            return
        }
        const {data, changeProperty} = this.props;
        data.winery.wines.value.push(wine);
        changeProperty('winery', 'wines', data.winery.wines.value)
    };

    handleWineCategoryPick = (wine, idx, isNew) => {
        if (!wine) {
            return
        }

        if (isNew) {
            const {newWineCategory} = this.state;
            newWineCategory.wines.push(wine);
            this.setState({newWineCategory, modified: true});
            return;
        }
        const {wineCategories} = this.state;
        wineCategories[idx].wines.push(wine);
        this.setState({wineCategories: [...wineCategories], modified: true})
    };

    handleCategoryNameChange = (val, idx, isNew) => {
        if (isNew) {
            const {newWineCategory} = this.state;
            newWineCategory.category = val;
            this.setState({newWineCategory, modified: true});
            return;
        }
        const {wineCategories} = this.state;
        wineCategories[idx].category = val;
        this.setState({wineCategories, modified: true})
    };

    handleCategoryDelete = idx => {
        const {wineCategories} = this.state;
        wineCategories.splice(idx, 1);
        this.setState({wineCategories, modified: true})
    };

    handleCategoryAdd = () => {
        const {wineCategories, newWineCategory} = this.state;
        wineCategories.push(newWineCategory);
        this.setState({wineCategories, newWineCategory: {category: '', wines: []}})
    };

    openImageBrowser = (handleImageOpen) => {
        this.setState({browserOpen: true, handleImageOpen})
    };

    handleSingleImageOpen = field => {
        const {changeProperty} = this.props;
        this.openImageBrowser(path => {
            changeProperty('winery', field, path);
            this.setState({browserOpen: false})
        });
    };

    handleArrayImageOpen = field => {
        const {changeProperty, data} = this.props;
        this.openImageBrowser(path => {
            const fields = data.winery[field].value;

            fields.push({image: path, link: ''});
            changeProperty('winery', field, fields);
            this.setState({browserOpen: false})
        });
    };

    handleLinkEdit = (value, idx, field) => {
        const {changeProperty, data} = this.props;
        const fields = data.winery[field].value;
        fields[idx].link = value;

        changeProperty('winery', field, fields);
    };

    render() {
        const {mode, newWineCategory, browserOpen, wineCategories, handleImageOpen} = this.state;
        const {data, formControl, modified, changeProperty, resetData} = this.props;
        const {winery} = data;
        const modifiedNewWine = this.state.modified;

        if (!winery) {
            return null;
        }

        console.log(winery.wines.value.map(w => ({id: w.id, name: w.name, order: w.order})));
        return (
            <div>
                <ImageBrowserDialog handleOpen={handleImageOpen} open={browserOpen}
                                    onClose={() => this.setState({browserOpen: false})}/>
                <FormActionBar reset={resetData} modified={modified || modifiedNewWine}
                               update={mode === 'edit' ? this.updateWinery : this.createWinery}/>
                <Grid container>
                    <Grid xs={12} item>
                        <FloatingBox sectionTitle="Fő tulajdonságok">
                            <ControlledField title="Borászat"
                                             value={winery.name.value}
                                             onChange={changeProperty}
                                             formControl={formControl['winery.name']}
                                             name="name" obj="winery"/>
                            <ControlledField title="Borász"
                                             value={winery.enologist.value}
                                             onChange={changeProperty}
                                             formControl={formControl['winery.enologist']}
                                             name="enologist" obj="winery" exampleText=""
                                             className=""/>
                            <ControlledField title="Borvidék"
                                             value={winery.vineyard.value}
                                             onChange={changeProperty}
                                             formControl={formControl['winery.vineyard']}
                                             name="vineyard" obj="winery"/>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '20%',
                                justifyContent: 'space-between'
                            }}>
                                <Typography variant="h6">Aktív</Typography>
                                <Checkbox color="primary" checked={winery.active.value}
                                          onChange={(e, v) => changeProperty('winery', 'active', v)}/>
                            </div>
                            <div style={{
                                alignItems: 'center',
                                width: '40%',
                            }}>
                                <Typography variant="h6">Filtereknél, boroknál megjelenítendő név</Typography>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant="body1">Borászat neve</Typography>
                                    <Checkbox color="primary" checked={winery.displayNameIsWinery.value}
                                              onChange={() => changeProperty("winery", "displayNameIsWinery", true)}/>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant="body1">Borász neve</Typography>
                                    <Checkbox color="primary" checked={!winery.displayNameIsWinery.value}
                                              onChange={() => changeProperty("winery", "displayNameIsWinery", false)}/>
                                </div>
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '30%',
                                }}>
                                    <Typography variant="h6">Az év bortermelője díj</Typography>
                                    <Checkbox color="primary" checked={winery.enologistOfTheYear.value}
                                              onChange={(e, v) => changeProperty('winery', 'enologistOfTheYear', v)}/>
                                </div>
                                <div style={{width: '30%'}}>
                                    <ControlledField title="Évszám"
                                                     value={winery.yearEnologistOfTheYear.value}
                                                     onChange={changeProperty}
                                                     disabled={!winery.enologistOfTheYear.value}
                                                     formControl={formControl['winery.yearEnologistOfTheYear']}
                                                     name="yearEnologistOfTheYear" obj="winery"/>
                                </div>
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '30%',
                                }}>
                                    <Typography variant="h6">Borászok borásza díj</Typography>
                                    <Checkbox color="primary" checked={winery.enologistOfEnologists.value}
                                              onChange={(e, v) => changeProperty('winery', 'enologistOfEnologists', v)}/>
                                </div>
                                <div style={{width: '30%'}}>
                                    <ControlledField title="Évszám"
                                                     value={winery.yearEnologistOfEnologists.value}
                                                     onChange={changeProperty}
                                                     disabled={!winery.enologistOfEnologists.value}
                                                     formControl={formControl['winery.yearEnologistOfEnologists']}
                                                     name="yearEnologistOfEnologists" obj="winery"/>
                                </div>
                            </div>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} item>
                        <FloatingBox sectionTitle="Leírás">
                            <ControlledField title="Név"
                                             value={winery.description.value}
                                             onChange={changeProperty}
                                             formControl={formControl['winery.description']}
                                             name="description" obj="winery" exampleText=""
                                             className="" multiline rows={8}
                                             style={{width: '100%', marginTop: '1em'}}/>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} md={4} item>
                        <FloatingBox style={{height: '90%'}} sectionTitle="Logó">
                            <ImageUpload title="Logó"
                                         handleOpen={() => this.handleSingleImageOpen('logoImage')}
                                         val={winery.logoImage.value}/>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} md={4} item>
                        <FloatingBox style={{height: '90%'}} sectionTitle="Arckép">
                            <ImageUpload title="Borász kép"
                                         handleOpen={() => this.handleSingleImageOpen('coverImage')}
                                         val={winery.coverImage.value}/>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} md={4} item>
                        <FloatingBox style={{height: '90%'}} sectionTitle="Borítókép">
                            <ImageUpload title="Háttérkép"
                                         handleOpen={() => this.handleSingleImageOpen('wideImage')}
                                         val={winery.wideImage.value}/>

                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <FloatingBox style={{height: '90%'}} sectionTitle="Első bekezdés">
                            <div>
                                <ImageUpload val={winery.firstParagraphImages.value} isArray
                                             editLink={(val, idx) => this.handleLinkEdit(val, idx, 'firstParagraphImages')}
                                             handleOpen={() => this.handleArrayImageOpen('firstParagraphImages')}
                                             handleRemove={idx => {
                                                 winery.firstParagraphImages.value.splice(idx, 1);
                                                 changeProperty('winery', 'firstParagraphImages', winery.firstParagraphImages.value);
                                             }}
                                />
                            </div>
                            <ControlledField title="Leírás"
                                             value={winery.firstParagraph.value}
                                             onChange={changeProperty}
                                             multiline rows={4}
                                             formControl={formControl['winery.firstParagraph']}
                                             name="firstParagraph" obj="winery"/>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <FloatingBox style={{height: '90%'}} sectionTitle="Második bekezdés">
                            <div>
                                <ImageUpload val={winery.secondParagraphImages.value} isArray
                                             editLink={(val, idx) => this.handleLinkEdit(val, idx, 'secondParagraphImages')}
                                             handleOpen={() => this.handleArrayImageOpen('secondParagraphImages')}
                                             handleRemove={idx => {
                                                 winery.secondParagraphImages.value.splice(idx, 1);
                                                 changeProperty('winery', 'secondParagraphImages', winery.secondParagraphImages.value);
                                             }}
                                />
                            </div>
                            <ControlledField title="Leírás"
                                             value={winery.secondParagraph.value}
                                             onChange={changeProperty}
                                             multiline rows={4}
                                             formControl={formControl['winery.secondParagraph']}
                                             name="secondParagraph" obj="winery"/>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} item>
                        <FloatingBox sectionTitle="Borok" style={{height: '95%'}}>
                            <div style={{width: '50%', margin: '0 auto'}}>
                                <ProductPicker onChange={this.handleNewWine}
                                               exclusions={{id: winery.wines.value.map(w => w.id)}}/>
                            </div>
                            <Grid container style={{maxHeight: 500, overflowY: 'auto'}}>
                                {winery.wines.value.sort((a, b) => a.order - b.order).map((wine, idx) =>
                                    <Grid item xs={12} sm={6} md={4} key={`${idx}-winery-wine-item`}>
                                        <ProductItem product={wine}
                                                  first={idx === 0}
                                                  last={idx === winery.wines.value.length - 1}
                                                  onDelete={() => {
                                                      const callback = () => {
                                                          winery.wines.value.splice(idx, 1);
                                                          changeProperty('winery', 'wines', winery.wines.value);
                                                      };
                                                      setConfirmationState(true, callback);
                                                  }}
                                                  onMove={{
                                                      forward: () => {
                                                          winery.wines.value[idx-1].order += 1;
                                                          winery.wines.value[idx].order -= 1;
                                                          changeProperty('winery', 'wines', winery.wines.value);
                                                      },
                                                      backward: () => {
                                                          winery.wines.value[idx+1].order -= 1;
                                                          winery.wines.value[idx].order += 1;
                                                          changeProperty('winery', 'wines', winery.wines.value);
                                                      }
                                                  }}
                                        /></Grid>)}
                            </Grid>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} item>
                        <FloatingBox sectionTitle="Bor családok">
                            <div style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                maxWidth: '90vw',
                                overflowX: 'auto',
                            }}>
                                <div style={{margin: '1em'}}>
                                    <WineCategory isNew handlePick={this.handleWineCategoryPick}
                                                  wines={winery.wines.value} handleDeleteItem={id => {
                                        const idx = newWineCategory.wines.findIndex(w => w.id === id);
                                        newWineCategory.wines.splice(idx, 1);
                                        this.setState({newWineCategory})
                                    }}
                                                  handleNameChange={this.handleCategoryNameChange}
                                                  handleAdd={this.handleCategoryAdd}
                                                  wineCategory={newWineCategory}/>
                                </div>
                                {wineCategories.map((cat, idx) => <div style={{margin: '1em'}}>
                                    <WineCategory wineCategory={cat} handleNameChange={this.handleCategoryNameChange}
                                                  idx={idx} handleDelete={this.handleCategoryDelete}
                                                  wines={winery.wines.value}
                                                  handleDeleteItem={id => {
                                                      const idx = cat.wines.findIndex(w => w.id === id);
                                                      cat.wines.splice(idx, 1);
                                                      this.setState({
                                                          modified: true,
                                                          wineCategories: [...wineCategories]
                                                      })
                                                  }}
                                                  handlePick={this.handleWineCategoryPick}/>
                                </div>)}
                            </div>
                        </FloatingBox>
                    </Grid>
                    <Grid xs={12} item>
                        <FloatingBox sectionTitle="További adatok">
                            <ControlledField title="Alapítás éve"
                                             value={winery.year.value}
                                             onChange={changeProperty}
                                             formControl={formControl['winery.year']}
                                             name="year" obj="winery" exampleText=""
                                             className=""/>
                            <ControlledField title="Birtokméret"
                                             value={winery.vineyardTerritory.value}
                                             onChange={changeProperty}
                                             formControl={formControl['winery.vineyardTerritory']}
                                             name="vineyardTerritory" obj="winery" exampleText=""
                                             className=""/>
                            <ControlledField title="Talajösszetétel"
                                             value={winery.soilComposition.value}
                                             onChange={changeProperty}
                                             formControl={formControl['winery.soilComposition']}
                                             name="soilComposition" obj="winery" exampleText=""
                                             className=""/>
                            <ControlledField title="Szőlőfajták"
                                             value={winery.grapeTypes.value}
                                             onChange={changeProperty}
                                             formControl={formControl['winery.grapeTypes']}
                                             name="grapeTypes" obj="winery" exampleText=""
                                             className=""/>
                            <ControlledField title="Weboldal"
                                             value={winery.webPage.value}
                                             onChange={changeProperty}
                                             formControl={formControl['winery.webPage']}
                                             name="webPage" obj="winery" exampleText=""
                                             className=""/>
                        </FloatingBox>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withFormControl(WineryEditor, {winery: _.cloneDeep(winery)});