import {authServer, resourceServer} from "../constants";
import axios from 'axios';
import {accessRequestSuccess, doLogout} from "../redux/redux-user-service";

const tokenConstant = 'Authorization';
let accessToken = '';

export const login = (credentials = {}) => {
    return axios.post(`${authServer}/login`, JSON.stringify(credentials), {
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
    }).then(() => getAccessToken())
};

export const verifyAuthority = (res) => {
    return axios.get(`${authServer}/admin`, {
        headers: {
            'Content-Type': 'application/json',
            [tokenConstant]: accessToken
        },
        withCredentials: true
    })
        .then(() => accessRequestSuccess(res))
        .catch(() => logout())
};

export const register = (user) => {
    return post(`${authServer}/register`, JSON.stringify(user))
};

export const getAccessToken = () =>
    axios.get(`${authServer}/token`,
        {withCredentials: true})
        .then(res => {
            accessToken = res.headers.authorization;
            return res;
        })
        .then(res => verifyAuthority(res))
        .catch(err => doLogout());

export const logout = () => {
    get(`${authServer}/sign-out`)
        .then(res => {
            window.location.href = '/';
            accessToken = ''
        }).catch(err => console.log(err))
};

export const activateAccount = code => {
    return get(`${authServer}/activation/${code}`)
};

export const updateEmail = (id, emailId) => {
    return get(`${resourceServer}/users/${id}/${emailId}`)
};

export const restorePassword = data => {
    return post(`${authServer}/passes/new-password`, JSON.stringify(data))
};

export const updatePassword = data => {
    return post(`${authServer}/users:pass`, JSON.stringify(data))
};

export const validatePasswordRestoring = code => {
    return get(`${authServer}/passes/retrieve-password/${code}`)
};

export const lostPassword = (email) => {
    return post(`${authServer}/passes/lost-password`, JSON.stringify(email))
};

export const get = url => axios.get(url, {headers: {[tokenConstant]: accessToken}, withCredentials: true});

export const post = (url, body) => axios.post(url, body, {
    headers: {
        'Content-Type': 'application/json',
        [tokenConstant]: accessToken
    }, withCredentials: true
});

export const put = (url, body) => axios.put(url, body, {
    headers: {
        'Content-Type': 'application/json',
        [tokenConstant]: accessToken
    }, withCredentials: true
});

export const remove = (url) => axios.delete(url, {headers: {[tokenConstant]: accessToken}, withCredentials: true});