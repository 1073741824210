import React from 'react';
import MomentUtils from "@material-ui/pickers/adapter/moment";
import {DateTimePicker, LocalizationProvider} from "@material-ui/pickers";
import {Controller} from "react-hook-form";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

function ControlledDatetimePicker({control, errors, title, name, defaultValue, setValue, min, value}) {
    const val = value ? moment(value) : defaultValue ? moment(defaultValue) : null;
    return <LocalizationProvider locale="hu" dateAdapter={MomentUtils}>
        <Controller
            as={DateTimePicker}
            minDate={moment(min)}
            value={val}
            clearText="Törlés"
            cancelText="Mégse"
            onChange={v => setValue(name, v)}
            rawValue={val}
            label={title}
            helperText={errors[name] && errors[name].message}
            control={control}
            name={name}
            clearable
            mask="____.__.__. __:__"
            defaultValue={defaultValue}
            renderInput={props => <TextField variant="outlined" {...props} />}
            error={errors[name]}
        />
    </LocalizationProvider>
}

export default ControlledDatetimePicker;