import React, {useState} from 'react';
import {searchProducts} from "../../../service/wine-service";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ProductItem from "../product/product-item";

const ProductPicker = ({onChange, exclusions, inclusions, options}) => {
    const [wines, setWines] = useState([]);
    const [name, setName] = useState('');

    const limit = 5;

    const handleChange = (e, v) => {
        onChange(e, v);
        setName('');
    };

    const onSearch = e => {
        const name = e.target.value;
        if (options) {
            setWines(options.filter(value => {
                if (exclusions)
                return exclusions.id.every(e => e !== value.id) && value.name.toLowerCase().indexOf(name.toLowerCase()) > -1
                else return options
            }));
            setName(name);
            return;
        }

        searchProducts({
            page: 0,
            limit,
            propertyFilter: {exclusionMap: exclusions, propertyMap: inclusions, regexMap: {name}},
            sortResource: {}
        }).then(res => {
            setName(name);
            setWines(res.data.content);
        })
    };

    return <div style={{margin: '1.5em 0'}}>
        <Autocomplete
            options={wines}
            onChange={handleChange}
            inputValue={name}
            getOptionLabel={(option) => option.name}
            renderOption={wine => <ProductItem product={wine}/>}
            renderInput={(params) => <TextField onBlur={() => {
                setWines([]);
                setName('')
            }} value={name} fullWidth label="Bor hozzáadása..." onChange={onSearch} {...params} variant="outlined"/>}
        />
    </div>
};

export default ProductPicker;