export const errorMessages = {
    empty: "Kötelező mező!",
    email: "Érvénytelen email formátum!",
    password: 'Minimum 8 karakter, legalább egy szám és egy speciális karakter szükséges!',
    rePassword: 'A megadott jelszavak nem egyeznek!',
    alphabetical: "Ehhez a mezőhöz csak betűket adjon meg!",
    numerical: "Ehhez a mezőhöz csak számokat adjon meg!",
    taxNumber: "Érvénytelen adószám formátum!",
    phoneNumber: "Érvénytelen telefonszám!",
    companyName: "Ehhez a mezőhöz csak betűket és számokat adjon meg!",
};