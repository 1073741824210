import React from 'react';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import {Close} from "@material-ui/icons";

function ImageUpload({val, handleOpen, isArray, handleRemove, editLink}) {
    if (isArray) {
        return <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            maxHeight: '90%'
        }}>
            <div style={{
                height: 250,
                overflowY: 'auto',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center'
            }}>
                {
                    val.map((i, idx) => <div
                        style={{
                            width: '90%',
                            height: 250,
                            margin: '1em 0',
                            justifyContent: 'space-between',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        <div style={{width: '40%'}}>
                            <img alt="slide-item" style={{width: '100%'}} src={i.image}/>
                        </div>
                        <TextField onChange={e => editLink(e.target.value, idx)} value={i.link} variant="outlined" label="Forrás"/>
                        <IconButton onClick={() => handleRemove(idx)}><Close/></IconButton>
                    </div>)
                }
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
                margin: '.5em',
            }}>
                <Button
                    variant="contained"
                    component="label"
                    onClick={handleOpen}
                >
                    Kép kiválasztása
                </Button>
            </div>
        </div>
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '90%',
            alignItems: 'center',
            justifyContent: 'center'
        }}>

            <div style={{width: '70%', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: '75%'}}>
                <img style={{width: '100%'}} src={val}/>
                <p style={{width: '100%'}}>{val ? val.split("=")[val.split("=").length - 1] : null}</p>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '80%',
                margin: '2.5em'
            }}>
                <Button
                    variant="contained"
                    component="label"
                    onClick={handleOpen}
                >
                    Kép kiválasztása
                </Button>
            </div>
        </div>
    );
}

export default ImageUpload;