import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {setError, setSuccess} from "../../redux/redux-feedback-service";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import ImageBrowserDialog from "../common/file-browser/image-browser-dialog";
import FloatingBox from "../common/form/floating-box";
import ImageUpload from "../common/form/image-upload";
import FormActionBar from "../common/form/form-action-bar";
import ControlledInput from "../form/base/controlled-input";
import {getTour, saveTour, updateTour} from "../../service/tour-service";
import ControlledDatetimePicker from "../form/base/controlled-datetime-picker";
import moment from "moment";
import ControlledCheckbox from "../form/base/controlled-checkbox";

const TourDetail = ({match}) => {
    const [tour, setTour] = useState(null);
    const [mode, setMode] = useState('');
    const [image, setImage] = useState('');
    const [attachment, setAttachment] = useState('');
    const [browserOpen, setBrowserOpen] = useState({open: false, type: ''});
    const [loading, setLoading] = useState(true);

    const {control, errors, setValue, getValues, watch, handleSubmit, reset, formState} = useForm();

    const start = watch('start');
    const end = watch('end');

    useEffect(() => {
        if (match.params && match.params.id !== 'uj') {
            getTour(match.params.id).then(res => {
                setTour(res.data);
                setMode('edit');
                setValue('link', res.data.link)
            }).finally(() => setLoading(false))
        } else {
            setLoading(false);
            setTour({});
        }
    }, []);

    const doUpdateTour = () => {
        doTourRequest(updateTour)
    };

    const createTour = () => {
        doTourRequest(saveTour)
    };

    const doTourRequest = method => {
        const t = {
            id: tour.id,
            image: image ? image : tour.image,
            attachment: attachment ? attachment : tour.attachment,
            eventLink: getValues('eventLink'),
            joinLink: getValues('joinLink'),
            name: getValues('name'),
            active: getValues('active'),
            ownActivity: getValues('ownActivity'),
            start,
            end,
            details: getValues('details').split(";").map(i => i.trim()).filter(i => !!i),
        };

        method(t)
            .then(res => {
                setSuccess('Sikeres mentés');
                window.history.back();
            })
            .catch(err => setError('Sikertelen mentés'))
    };

    if (!tour) {
        return <div>
            <Backdrop open/>
        </div>
    }

    return (
        <Grid container>
            <ImageBrowserDialog handleOpen={path => {
                if (browserOpen.type === 'image') {
                    setImage(path);
                } else {
                    setAttachment(path);
                }
                setBrowserOpen({open: false, type: ''})
            }} open={browserOpen.open} onClose={() => setBrowserOpen({open: false, type: ''})}/>
            <Grid item xs={6}>
                <FloatingBox sectionTitle="Kép">
                    <ImageUpload handleOpen={() => setBrowserOpen({open: true, type: 'image'})} val={image || tour.image}/>
                </FloatingBox>
            </Grid>
            <Grid item xs={6}>
                <FloatingBox sectionTitle="Csatolmány">
                    <ImageUpload handleOpen={() => setBrowserOpen({open: true, type: 'attachment'})} val={attachment || tour.attachment}/>
                </FloatingBox>
            </Grid>
            <Grid xs={12} item>
                <FloatingBox sectionTitle="Tulajdonságok">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <ControlledCheckbox title="Aktív" defaultValue={tour.active} control={control}
                                                value={tour.active} color="primary" name="active"
                            />
                            <ControlledCheckbox title="Borkóstoló" defaultValue={tour.ownActivity} control={control}
                                                value={tour.ownActivity} color="primary" name="ownActivity"
                            />
                            <ControlledInput title="Név" fullWidth
                                             defaultValue={tour.name}
                                             onChange={e => setValue('name', e.target.value, {shouldDirty: true})}
                                             name="name" errors={errors} control={control}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledInput title="Leírás felsorolás pontokban ;-vel elválasztva" fullWidth
                                             defaultValue={tour.details ? tour.details.join(" ; ") : ""}
                                             onChange={e => setValue('details', e.target.value, {shouldDirty: true})}
                                             name="details" errors={errors} control={control} multiline rows={3}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledInput title="Esemény linkje" fullWidth
                                             defaultValue={tour.eventLink}
                                             onChange={e => setValue('eventLink', e.target.value, {shouldDirty: true})}
                                             name="eventLink" errors={errors} control={control}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledInput title="Jelentkezési ív linkje" fullWidth
                                             defaultValue={tour.joinLink}
                                             onChange={e => setValue('joinLink', e.target.value, {shouldDirty: true})}
                                             name="joinLink" errors={errors} control={control}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledDatetimePicker control={control} getValues={getValues} errors={errors}
                                                      setValue={setValue} title="Túra kezdete" name="start"
                                                      min={null}
                                                      value={start} defaultValue={moment(tour.start)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledDatetimePicker control={control} errors={errors} setValue={setValue}
                                                      title="Túra vége" name="end" value={end} min={start}
                                                      defaultValue={moment(tour.end)}/>
                        </Grid>
                    </Grid>
                </FloatingBox>
            </Grid>
            <FormActionBar modified={formState.isDirty || (image && image !== tour.image)} reset={reset}
                           update={mode === 'edit' ? handleSubmit(doUpdateTour) : handleSubmit(createTour)}/>
        </Grid>
    );
};

export default TourDetail;