import React from 'react';
import PageableContainer from "../common/table/pageable-container";
import moment from "moment";
import {removeSale, searchSales} from "../../service/sale-service";

const sortOptions = [
    {label: 'Kezdet növekvő', field: 'start', order: 'asc'},
    {label: 'Kezdet csökkenő', field: 'start', order: 'desc'},
    {label: 'Vége növekvő', field: 'end', order: 'asc'},
    {label: 'Vége csökkenő', field: 'end', order: 'desc'},
];

function SaleContainer(props) {

    const getSales = sales => {
        return sales.map(sale => ({
            Kezdete: moment(sale.start).format("YYYY. MM. DD."),
            Vége: moment(sale.end).format("YYYY. MM. DD."),
            Aktív: sale.active,
            'Termékek száma': sale.items ? sale.items.length : 0,
            'Rendelések száma': sale.timesOrdered,
        }));
    };

    return <PageableContainer sortOptions={sortOptions} fetchMethod={searchSales} urlPrefix="leertekeles"
                              pageableDataMethod={getSales} deleteMethod={removeSale} urlParam={'id'}/>
}

export default SaleContainer;