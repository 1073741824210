import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import FloatingBox from "../common/form/floating-box";
import {getOrder, setNextStatus, updateOrder} from "../../service/order-service";
import Button from "@material-ui/core/Button";
import {formatPrice} from "../../service/formatter";
import FormActionBar from "../common/form/form-action-bar";
import 'moment/locale/hu'
import {setError, setSuccess} from "../../redux/redux-feedback-service";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import Checkbox from "@material-ui/core/Checkbox";
import {withRouter} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import {getTranslationByKey} from "../../redux/redux-translation-service";
import * as _ from 'lodash'
import {setConfirmationState} from "../../redux/redux-confirmation-service";
import SimpleForm from "../form/single/simple-form";
import {useForm} from "react-hook-form";
import {getProductVars} from "../../service/product-service";

const OrderDetailContainer = ({match}) => {
    const [order, setOrder] = useState(null);
    const [originalStatuses, setOriginalStatuses] = useState(null);
    const [notify, setNotify] = useState(true);
    const [loading, setLoading] = useState(true);

    const {errors, control, setValue, getValues, formState} = useForm();

    useEffect(() => {
        const fetchOrder = () => {
            if (match && match.params)
                getOrder(match.params.id)
                    .then(res => {
                        setOrder(res.data);
                        setOriginalStatuses(res.data.statuses);
                    }).finally(() => setLoading(false))
        };

        if (!order) {
            fetchOrder();
        }
    }, [order, match]);

    const update = () => {
        setLoading(true);
        order.items.forEach(item => {
            item.type = item.product.type;
            item.product = item.product.id;
        });
        order.internalNote = getValues('internalNote');
        updateOrder(order, notify).then(res => {
            setSuccess('Sikeres mentés');
            window.history.back();
        })
            .catch(err => setError('Sikertelen mentés'))
            .finally(() => setLoading(false))
    };

    const steps = {
        IN_STORE: {
            ONLINE_CREDIT_CARD: ['ONLINE_PAYMENT_COMPLETE', 'READY_IN_STORE', 'DONE'],
            ELSE: ['NEW', 'READY_IN_STORE', 'DONE']
        },
        PACKAGE_POINT: {
            ONLINE_CREDIT_CARD: ['ONLINE_PAYMENT_COMPLETE', 'READY_IN_STORE', 'DONE'],
            ELSE: ['NEW', 'READY_IN_STORE', 'DONE']
        },
        DELIVERY: {
            ONLINE_CREDIT_CARD: ['ONLINE_PAYMENT_COMPLETE', 'BEING_SHIPPED', 'DONE'],
            ELSE: ['NEW', 'BEING_SHIPPED', 'DONE']
        },
        EMAIL: {
            ONLINE_CREDIT_CARD: ['ONLINE_PAYMENT_COMPLETE', 'DONE'],
            ELSE: ['NEW', 'DONE']
        },
        ONLINE_PAYMENT_CANCELED: ['ONLINE_PAYMENT_CANCELED'],
        ONLINE_PAYMENT_FAILED: ['ONLINE_PAYMENT_FAILED'],
        ONLINE_PAYMENT_TIMEOUT: ['ONLINE_PAYMENT_TIMEOUT'],
    };

    const getActiveSequence = order => {
        let sequence = steps[getActiveStatus(order).status];
        if (sequence) {
            return sequence
        }

        return steps[order.deliveryType][order.paymentMethod === 'ONLINE_CREDIT_CARD' ? order.paymentMethod : 'ELSE'];
    };

    const getActiveStatus = (order) => {
        return order.statuses.filter(s => s.active)[0];
    };

    const nextStatus = () => setConfirmationState(
        true, () => setNextStatus(order.id).then(r => setOrder(r.data)),
        'Biztosan tovább lépteti a rendelés státuszt?');

    if (!order || loading) {
        return null
    }

    return (
        <Grid container>
            <Grid xs={12} md={3} item>
                <FloatingBox sectionTitle="Elérhetőség" style={{height: '95%'}}>
                    <div><b>Név</b></div>
                    <div>{order.name}</div>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Email</div>
                    <div>{order.email}</div>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Telefon</div>
                    <div>{order.phoneNumber}</div>
                </FloatingBox>
            </Grid>
            <Grid xs={12} md={3} item>
                <Grid container>
                    <Grid item xs={12}>
                        <FloatingBox sectionTitle="Fizetés" style={{height: '90%'}}>
                            <div>{getTranslationByKey(order.paymentMethod)}</div>
                        </FloatingBox>
                    </Grid>
                    <Grid item xs={12}>
                        <FloatingBox sectionTitle="Szállítás" style={{height: '90%'}}>
                            <div>{getTranslationByKey(order.deliveryType)}</div>
                        </FloatingBox>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} md={6} item>
                <FloatingBox sectionTitle="Státusz" style={{height: '90%'}}>
                    <Stepper
                        activeStep={getActiveSequence(order).indexOf(getActiveStatus(order).status)}
                        alternativeLabel>
                        {getActiveSequence(order).map((label) => (
                            <Step key={label}>
                                <StepLabel>{getTranslationByKey(label)}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div style={{textAlign: 'center'}}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '1.5em 0'
                        }}>
                            <div style={{fontSize: '1.2em'}}>Vásárló értesítése e-mailen</div>
                            <Checkbox checked={notify} onChange={(e, v) => setNotify(v)} color="primary"/>
                        </div>
                        <Button color="primary"
                                disabled={getActiveSequence(order).length - 1 === getActiveSequence(order).indexOf(getActiveStatus(order).status)}
                                variant="contained" onClick={nextStatus}>Következő státusz</Button>
                    </div>
                </FloatingBox>
            </Grid>
            <Grid xs={12} md={4} item>
                <FloatingBox sectionTitle="Belső jegyzet" style={{height: '90%'}}>
                    <SimpleForm title="Jegyzet" control={control} fullWidth errors={errors} name='internalNote'
                                multiline onChange={e => setValue('internalNote', e.target.value, {shouldDirty: true})}
                                rows={10} defaultValue={order.internalNote || ''}/>
                </FloatingBox>
            </Grid>
            <Grid xs={12} md={4} item>
                <FloatingBox sectionTitle="Számlázási cím" style={{height: '90%'}}>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Név</div>
                    <div>{order.billingAddress.name}</div>
                    {order.billingAddress.companyName && <div style={{fontWeight: 'bold', marginTop: 10}}>Cégnév</div>}
                    {order.billingAddress.companyName && <div>{order.billingAddress.companyName}</div>}
                    {order.billingAddress.taxNumber && <div style={{fontWeight: 'bold', marginTop: 10}}>Adószám</div>}
                    {order.billingAddress.taxNumber && <div>{order.billingAddress.taxNumber}</div>}
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Irányítószám</div>
                    <div>{order.billingAddress.zipCode}</div>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Település</div>
                    <div>{order.billingAddress.city}</div>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Cím</div>
                    <div>{order.billingAddress.address}</div>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Emelet, ajtó...</div>
                    <div>{order.billingAddress.other}</div>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Megjegyzés</div>
                    <div>{order.billingAddress.note}</div>
                </FloatingBox>
            </Grid>
            <Grid xs={12} md={4} item>
                <FloatingBox sectionTitle="Szállítási cím" style={{height: '90%'}}>
                    {order.deliveryAddress.name && <div style={{fontWeight: 'bold', marginTop: 10}}>Név</div>}
                    {order.deliveryAddress.name && <div>{order.deliveryAddress.name}</div>}
                    {order.deliveryAddress.phoneNumber &&
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Telefon</div>}
                    {order.deliveryAddress.phoneNumber && <div>{order.deliveryAddress.phoneNumber}</div>}
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Irányítószám</div>
                    <div>{order.deliveryAddress.zipCode}</div>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Település</div>
                    <div>{order.deliveryAddress.city}</div>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Cím</div>
                    <div>{order.deliveryAddress.address}</div>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Emelet, ajtó...</div>
                    <div>{order.deliveryAddress.other}</div>
                    <div style={{fontWeight: 'bold', marginTop: 10}}>Megjegyzés</div>
                    <div>{order.deliveryAddress.note}</div>
                </FloatingBox>
            </Grid>
            <Grid xs={12} item>
                <FloatingBox sectionTitle="Termékek">
                    {order.items.map((item, idx) => {
                        if (!_.isObject(item.product)) {
                            return null
                        }
                        const variables = getProductVars(item.product);
                        return <div key={`checkout_item_${idx}`}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '1em 0'
                            }}>
                                <div style={{width: '50%'}}>
                                    <div style={{fontSize: '1.1em', fontWeight: 'bold'}}>{variables.main}</div>
                                    <div style={{fontSize: '1em', fontWeight: 'bold'}}>{variables.sub}</div>
                                    <div style={{display: 'flex', alignItems: 'center', margin: '.5em 0'}}>
                                        {item.unitDiscount ? <div>
                                        <span style={{
                                            textDecoration: 'line-through',
                                            marginRight: 10
                                        }}>{item.unitPrice}</span>
                                            <span style={{color: 'red'}}>{item.unitPrice - item.unitDiscount}</span>
                                        </div> : <div>

                                            {item.product.price}
                                        </div>}
                                        <div style={{fontWeight: 'bold', marginLeft: 10}}>{`x ${item.count}`}</div>
                                    </div>
                                </div>
                                <div>
                                    {item.total}
                                </div>
                            </div>
                            <div>
                                <Divider/>
                            </div>
                        </div>
                    })}
                    {order.promotionCode ? <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '1em 0 .5em 0'
                    }}>
                        <div style={{fontSize: '0.9em', color: 'darkgreen', fontWeight: 'bold'}}>
                            Felhasznált kedvezmény kupon: {order.promotionCode.code}
                        </div>
                        <div style={{
                            fontSize: '1em',
                            color: 'darkgreen',
                            fontWeight: 'bold'
                        }}>{order.promotionCode.percentage}%</div>
                    </div> : null}{order.discount ? <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '1em 0 2.5em 0'
                    }}>
                        <div style={{fontSize: '0.9em', color: 'darkgreen', fontWeight: 'bold'}}>
                            Összes megtakarítás
                        </div>
                        <div style={{
                            fontSize: '1em',
                            color: 'darkgreen',
                            fontWeight: 'bold'
                        }}>{formatPrice(order.discount)}</div>
                    </div> : null}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '1em 0'
                    }}>
                        <div style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            Nettó részösszeg
                        </div>
                        <div style={{
                            fontSize: '1em',
                            fontWeight: 'bold'
                        }}>{formatPrice(Math.round(order.price / 1.27))}</div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '1em 0'
                    }}>
                        <div style={{fontSize: '0.9em', fontWeight: 'bold'}}>
                            ÁFA (27%)
                        </div>
                        <div style={{
                            fontSize: '1em',
                            fontWeight: 'bold'
                        }}>{formatPrice(Math.round(order.price / 1.27 * 0.27))}</div>
                    </div>
                    {order.deliveryType === 'DELIVERY' && <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '1em 0'
                    }}>
                        <div style={{fontSize: '.9em', fontWeight: 'bold'}}>
                            Szállítási költség
                        </div>
                        <div style={{fontSize: '.9em', fontWeight: 'bold'}}>{formatPrice(order.deliveryCost)}</div>
                    </div>}
                    {order.deliveryType === 'DELIVERY' && order.paymentMethod === "CASH" && <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '1em 0'
                    }}>
                        <div style={{fontSize: '.9em', fontWeight: 'bold'}}>
                            Utánvétes fizetés díja
                        </div>
                        <div style={{fontSize: '.9em', fontWeight: 'bold'}}>{formatPrice(order.cashOnDeliveryCost)}</div>
                    </div>}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '1em 0'
                    }}>
                        <div style={{fontSize: '1.3em', fontWeight: 'bold'}}>
                            Végösszeg
                        </div>
                        <div style={{
                            fontSize: '1.3em',
                            fontWeight: 'bold'
                        }}>{formatPrice(order.price + order.deliveryCost + order.cashOnDeliveryCost)}</div>
                    </div>
                </FloatingBox>
            </Grid>
            <FormActionBar modified={!_.isEqual(order.statuses, originalStatuses) || formState.isDirty} reset={() => {
                order.statuses = originalStatuses;
                setOrder({...order})
            }} update={update}/>
        </Grid>
    );
};

export default withRouter(OrderDetailContainer);