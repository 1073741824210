import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {setError, setSuccess} from "../../redux/redux-feedback-service";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import FloatingBox from "../common/form/floating-box";
import SimpleForm from "../form/single/simple-form";
import ControlledCheckbox from "../form/base/controlled-checkbox";
import FormActionBar from "../common/form/form-action-bar";
import {getSlide, saveSlide, updateSlide} from "../../service/slider-service";
import ImageUpload from "../common/form/image-upload";
import ImageBrowserDialog from "../common/file-browser/image-browser-dialog";
import Checkbox from "@material-ui/core/Checkbox";

function SliderDetail({match}) {
    const [slide, setSlide] = useState(null);
    const [mode, setMode] = useState('');
    const [image, setImage] = useState('');
    const [browserOpen, setBrowserOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const {control, errors, setValue, getValues, watch, handleSubmit, reset, formState} = useForm();

    const sloganOver = watch('sloganOver');
    const link = watch('link');

    useEffect(() => {
        if (match.params && match.params.id !== 'uj') {
            getSlide(match.params.id).then(res => {
                setSlide(res.data);
                setMode('edit');
                setValue('link', res.data.link)
            }).finally(() => setLoading(false))
        } else {
            setLoading(false);
            setSlide({});
        }
    }, []);

    const doUpdateSlide = () => {
        doSlideRequest(updateSlide)
    };

    const createSlide = () => {
        doSlideRequest(saveSlide)
    };

    const doSlideRequest = method => {
        const p = {
            id: slide.id,
            image: image ? image : slide.image,
            sloganOver: getValues('sloganOver') || false,
            link: getValues('link'),
        };

        method(p)
            .then(res => {
                setSuccess('Sikeres mentés');
                window.history.back();
            })
            .catch(err => setError('Sikertelen mentés'))
    };

    if (!slide) {
        return <div>
            <Backdrop open/>
        </div>
    }

    return (
        <Grid container>
            <ImageBrowserDialog handleOpen={path => {
                setImage(path);
                setBrowserOpen(false)
            }} open={browserOpen} onClose={() => setBrowserOpen(false)}/>
            <Grid item xs={12}>
                <FloatingBox sectionTitle="Kép">
                    <ImageUpload handleOpen={() => setBrowserOpen(true)} val={image || slide.image}/>
                </FloatingBox>
            </Grid>
            <Grid xs={12} item>
                <FloatingBox sectionTitle="Tulajdonságok">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <ControlledCheckbox control={control} value={sloganOver}
                                                title="Szlogen ráírása" name="sloganOver"
                                                defaultValue={slide.sloganOver}/>
                        </Grid>
                        <Grid item xs={12}>
                            <label>Legnépszerűbbek nézetre irányítás</label>
                            <Checkbox color="primary" onChange={() => setValue('link', '/webshop', {shouldDirty: true})}
                                      checked={link === '/webshop'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <label>Akciókra irányítás</label>
                            <Checkbox color="primary" onChange={() => setValue('link', '/webshop?onSale=true', {shouldDirty: true})}
                                      checked={link === '/webshop?onSale=true'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <label>Újdonság nézetre irányítás</label>
                            <Checkbox color="primary" onChange={() => setValue('link', '/webshop?sort=1', {shouldDirty: true})}
                                      checked={link === '/webshop?sort=1'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleForm control={control} errors={errors} defaultValue={slide.link}
                                        onChange={e => setValue('link', e.target.value)}
                                        name='link' title="Link"
                        />
                        </Grid>
                    </Grid>
                </FloatingBox>
            </Grid>
            <FormActionBar modified={formState.isDirty || (image && image !== slide.image)} reset={reset}
                           update={mode === 'edit' ? handleSubmit(doUpdateSlide) : handleSubmit(createSlide)}/>
        </Grid>
    );
}

export default SliderDetail;