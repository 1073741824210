export const formatPrice = number => {
    if (!number) {
        return '0 Ft';
    }

    let num = number.toString();
    if (num.length > 3) {
        const commaIndex = num.length - 3;
        num = num.slice(0, commaIndex) + ' ' + num.slice(commaIndex)
    }

    return `${num} Ft`
};