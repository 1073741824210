export const getProductVars = (product) => {
    if (product.type === 'WINE') {
        return {
            main: product.winery,
            sub: `${product.name} ${product.year}`,
            description: product.description,
            url: 'borok'
        };
    } else if (product.type === 'PACK') {
        return {
            main: product.name,
            sub: `${product.wines.length} palackos válogatás`,
            description: product.description,
            url: 'borcsomagok'
        };
    }
};