import {get, post, put, remove} from "./auth-service";
import {resourceServer} from "../constants";

export const getAllTours = () => {
    return get(`${resourceServer}/tours`)
};

export const getTour = id => {
    return get(`${resourceServer}/tours/${id}`)
};

export const saveTour = slide => {
    return post(`${resourceServer}/tours`, slide)
};

export const searchTours = resource => {
    return post(`${resourceServer}/tours:search?all=true`, resource)
};

export const updateTour = slide => {
    return put(`${resourceServer}/tours`, slide)
};

export const removeTour = id => {
    return remove(`${resourceServer}/tours/${id}`)
};