import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Close, KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";

function PackItem({pack, onDelete, onMove, first, last}) {
    return (
        <div style={{display: 'flex', margin: '1em 0', width: '100%', alignItems: 'center'}}>
            <div style={{width: '20%'}}>
                <img style={{width: '100%'}} src={pack.image}/>
            </div>
            <div>
                <div>
                    {pack.name}
                </div>
                <div>
                    {pack.wines.length} palackos válogatás
                </div>
            </div>
            {onDelete && <div style={{marginLeft: 5}}><IconButton onClick={onDelete}>
                <Tooltip title="Törlés">
                    <Close/>
                </Tooltip>
            </IconButton></div>}
            {onMove && <div style={{marginLeft: 5, display: 'flex', flexDirection: 'column'}}>
                <IconButton onClick={onMove.forward} disabled={first}>
                    <Tooltip title="Előrébb">
                        <KeyboardArrowUp/>
                    </Tooltip>
                </IconButton>
                <IconButton onClick={onMove.backward} disabled={last}>
                    <Tooltip title="Hátrább">
                        <KeyboardArrowDown/>
                    </Tooltip>
                </IconButton>
            </div>}
        </div>
    );
}

export default PackItem;