import {get, post, put, remove} from "./auth-service";
import {resourceServer} from "../constants";

export const getAllTranslations = () => {
    return get(`${resourceServer}/translations`)
};

export const getTranslation = id => {
    return get(`${resourceServer}/translations/${id}`)
};

export const saveTranslation = translation => {
    return post(`${resourceServer}/translations`, translation)
};

export const searchTranslation = resource => {
    return post(`${resourceServer}/translations:search`, resource)
};

export const updateTranslation = translation => {
    return put(`${resourceServer}/translations`, translation)
};

export const removeTranslation = id => {
    return remove(`${resourceServer}/translations/${id}`)
};