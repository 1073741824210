import {store} from "./store";

export const getTranslationByKey = (key) => {
    const locale = store.getState().locale;
    const translation = store.getState().translations.find(t => t.key === key);

    if (typeof key !== 'string') {
        return key;
    } else if (translation) {
        return store.getState().translations.find(t => t.key === key)[locale];
    }

    return key.charAt(0).toUpperCase() + key.substring(1);
};
