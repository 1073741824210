import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import ImageBrowser from "./image-browser";
import {getImageTree, mkdir, moveFiles, removeImage, saveImage} from "../../../service/image-service";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const ImageBrowserDialog = ({open, onClose, handleOpen}) => {
    const [imageTree, setImageTree] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getImageTree().then(res => {
            setImageTree(res.data);
        })
    }, []);

    const save = (file, path, type) => {
        setLoading(true);
        saveImage(file, path, type).then(() =>
            setTimeout(() =>
                getImageTree().then(res => {
                    setImageTree(res.data)
                }), 1000))
            .finally(() => {
                setLoading(false)
            });
    };

    const remove = (paths) => removeImage(paths).then(() =>
        setTimeout(() =>
            getImageTree().then(res => {
                setImageTree(res.data)
            }), 1000));

    const makeDir = (path, name) => mkdir(path + '/' + name).then(() =>
        setTimeout(() =>
            getImageTree().then(res => {
                setImageTree(res.data)
            }), 1000));

    const move = (path, paths) => moveFiles(path, paths).then(() =>
        setTimeout(() =>
            getImageTree().then(res => {
                setImageTree(res.data)
            }), 1000));

    if (!imageTree) {
        return <CircularProgress/>;
    }

    if (loading) {
        return <Backdrop open={loading} style={{zIndex: 9999}}>
            <CircularProgress color="primary"/>
        </Backdrop>
    }

    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <ImageBrowser handleOpen={handleOpen} onClose={onClose} imageTree={imageTree} makeDir={makeDir} move={move}
                          remove={remove} save={save}/>
        </Dialog>
    );
};

export default ImageBrowserDialog;