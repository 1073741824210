import React, {Component} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build-slimsider';

class CkRichEditor extends Component {
    render() {
        const { data, handleChange } = this.props;
        return (
            <div>
                <CKEditor
                    editor={ ClassicEditor }
                    data={data}
                    onChange={ ( event, editor ) => handleChange(editor.getData())}
                />
            </div>
        );
    }
}

export default CkRichEditor;