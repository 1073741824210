import React, {useEffect, useState} from 'react';
import {setError, setSuccess} from "../../../redux/redux-feedback-service";
import Grid from "@material-ui/core/Grid";
import FloatingBox from "../form/floating-box";
import PageableTable from "./pageable-table";
import {useLocation, useHistory} from "react-router-dom";
import * as _ from 'lodash';
import {setConfirmationState} from "../../../redux/redux-confirmation-service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function PageableContainer({sortOptions, fetchMethod, deleteMethod, pageableDataMethod, urlPrefix, regexKey = 'name', urlParam, regexFilter, withoutHeader, withoutCreation}) {
    const [limit, setLimit] = useState(16);
    const [data, setData] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        const sortIndex = query.get('sort');

        if (sortIndex >= 0) {
            history.push({pathname: `${history.location.pathname}`, search: query.toString()});
        } else {
            query.set('sort', '0');
            history.push({pathname: `${history.location.pathname}`, search: query.toString()});
        }
    }, []);

    useEffect(_.debounce(() => {
        fetch()
    }, 200), [history.location.search]);

    useEffect(() => {
        fetch()
    }, []);

    const fetch = () => {
        const page = parseInt(query.get('page')) - 1 || 0;
        const regexVal = query.get(regexKey);
        const sortIndex = query.get('sort') || 0;
        fetchMethod({page, limit, propertyFilter: {regexMap: {[regexKey]: regexVal}, range: []}, sortResource: sortOptions[sortIndex]})
            .then(res => {
                setData(res.data.content);
                setTotalElements(res.data.totalElements);
                setTotalPages(res.data.totalPages);
            })
            .catch(err => console.log(err));
    };

    const remove = idx => {
        const callback = () => deleteMethod(data[idx].id).then(() => {
            setSuccess("Sikeres törlés");
            fetch()
        }).catch(() => setError("Sikertelen törlés"));
        setConfirmationState(true, callback, '');
    };

    const onPageTurn = (e, v) => {
        query.set('page', v);
        history.push({pathname: `${history.location.pathname}`, search: query.toString()})
    };

    const navigateTo = idx => {
        const item = data[idx];
        history.push(`${urlPrefix}/${item[urlParam]}`)
    };

    const navigateToNew = () => {
        history.push(`${urlPrefix}/uj`)
    };

    const updateSort = e => {
        const sort = sortOptions.findIndex(s => s.label === e.target.value);
        query.set('sort', sort);
        history.push({pathname: `${history.location.pathname}`, search: query.toString()});
    };

    const setRegex = (key, regex) => {
        query.set(key, regex);
        history.push({pathname: `${history.location.pathname}`, search: query.toString()});
    };

    const getPageableData = () => {
        return pageableDataMethod(data);
    };

    const pageableData = data ? getPageableData() : {};

    const page = parseInt(query.get('page')) || 1;
    const name = query.get('name') || '';
    const sortIndex = query.get('sort') || 0;
    return (
        <Grid container>
            <Grid item xs={12}>
                <FloatingBox>
                    <PageableTable
                        turnPage={onPageTurn}
                        totalPages={totalPages}
                        totalElements={totalElements}
                        page={page}
                        data={pageableData}
                        limit={limit}
                        onEdit={navigateTo}
                        onCreate={navigateToNew}
                        onDelete={remove}
                        sort={sortOptions[sortIndex]}
                        regexMap={{name}}
                        setSort={updateSort}
                        setRegex={setRegex}
                        sortOptions={sortOptions}
                        filter={regexFilter}
                        withoutHeader={withoutHeader}
                        withoutCreation={withoutCreation}
                    />
                </FloatingBox>
            </Grid>
        </Grid>
    );
}

export default PageableContainer;