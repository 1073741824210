import {resourceServer} from "../constants";
import {get, post, put, remove} from './auth-service'

export const getAllUsers = () => {
    return get(`${resourceServer}/users`)
};

export const getThisUser = id => {
    return get(`${resourceServer}/users/${id}`)
};

export const searchUsers = searchResource => {
    return post(`${resourceServer}/users:search`, searchResource)
};

export const setVipUser = id => {
    return get(`${resourceServer}/users:vip/${id}`)
};

export const removeThisUser = id => {
    return remove(`${resourceServer}/users/${id}`)
};

