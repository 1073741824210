import {get, post, put, remove} from "./auth-service";
import {resourceServer} from "../constants";

export const getAllSales = () => {
    return get(`${resourceServer}/sales`)
};

export const getSale = id => {
    return get(`${resourceServer}/sales/${id}`)
};

export const saveSale = promo => {
    return post(`${resourceServer}/sales`, promo)
};

export const searchSales = resource => {
    return post(`${resourceServer}/sales:search`, resource)
};

export const updateSale = promo => {
    return put(`${resourceServer}/sales`, promo)
};

export const removeSale = id => {
    return remove(`${resourceServer}/sales/${id}`)
};